import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class Themes extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Themes */}
        <div class="container-fluid py-5">
          <div class="container">
            <div
              class="border-start border-5 border-primary ps-5 mb-5"
              // style="max-width: 600px;"
            >
              <h6 class="text-primary text-uppercase">Themes</h6>
              <h1 class="display-5 text-uppercase mb-0">Our Stunning Themes</h1>
            </div>
            <div class="row g-5">
              <div class="col-md-3">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Jupiter</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/jupiter.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Mars</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/mars.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Moon</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/moon.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Earth</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/earth.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
