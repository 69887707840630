import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { DemoLinks } from "./Home";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Features } from "./Products";
import { Showcase } from "./Showcase";
import { Pricing } from "./Pricing";
import { Services } from "./Home";
import { CommonModules } from "./CommonModules";

export class Retail extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div class="container-fluid py-5">
          <div class="container">
            <div class="row gx-4 py-2">
              <div class="col-lg-12">
                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h4 class="display-6  mb-0">
                    Modern Retail & WholeSale Solution (B2C/B2B/D2C)
                  </h4>
                  <a href="images/Retail/retailoverview.png" target="_blank">
                    <img
                      class="w-100 rounded"
                      src="images/Retail/retailoverview.png"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </div>
                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h4 class="display-6  mb-0">
                    Business Workflow - Our Solution
                  </h4>
                  <a href="images/Retail/retailworkflow.png" target="_blank">
                    <img
                      class="w-100 rounded"
                      src="images/Retail/retailworkflow.png"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <>
              {/* eCommerce */}
              <Table size="sm" className="bg-light">
                <tr class="">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/customerportal.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>

                      <td width="60%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Store - B2C, B2B, D2C
                        </h2>
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <li>
                            Online Stores are powerful platform for selling and
                            to reach broader customers. It's branding. We would
                            create an online Store under your brand name (domain
                            address) where customers/Sellers can order Products
                            Online.
                          </li>
                          <li>
                            Your online restaurant would be integrated with
                            Payment gateway and Cash-On-Delivery features.
                            Online Store supports over 100 beautiful themes and
                            layouts. unlimited customizable themes.
                          </li>
                          <li>
                            Once the order is received, You could manage the
                            order and deliver it to the recipient yourself.
                            Customers or third-party delivery services such as
                            Dunzo and Shiprocket.
                          </li>
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Store - B2C, B2B, D2C
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/customerportal.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <li>
                            Online Stores are powerful platform for selling and
                            to reach broader customers. It's branding. We would
                            create an online Store under your brand name (domain
                            address) where customers/Sellers can order Products
                            Online.
                          </li>
                          <li>
                            Your online restaurant would be integrated with
                            Payment gateway and Cash-On-Delivery features.
                            Online Store supports over 100 beautiful themes and
                            layouts. unlimited customizable themes.
                          </li>
                          <li>
                            Once the order is received, You could manage the
                            order and deliver it to the recipient yourself.
                            Customers or third-party delivery services such as
                            Dunzo and Shiprocket.
                          </li>
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>
              {/* POS */}
              <Table size="sm" className="border-0">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Smart POS [Point-Of-Sale]
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Direct Store Purchase, Store Pickup Orders, and
                          Home Delivery orders with ease and user-friendly POS.
                          POS is a Front-end and Backend is our Order Management
                          system to manage packaging and delivery. The POS
                          provides Insight about billing, orders and queues
                          along with detailed Dashboard. POS shall be integrated
                          with Printers, Barcode Scanner, Weighing Machine, and
                          Email system for paperless Invoice.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/Pos.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Smart POS [Point-Of-Sale]
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/Pos.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Direct Store Purchase, Store Pickup Orders, and
                          Home Delivery orders with ease and user-friendly POS.
                          POS is a Front-end and Backend is our Order Management
                          system to manage packaging and delivery. The POS
                          provides Insight about billing, orders and queues
                          along with detailed Dashboard. POS shall be integrated
                          with Printers, Barcode Scanner, Weighing Machine, and
                          Email system for paperless Invoice.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>
              <CommonModules></CommonModules>>{/* KIOSK */}
              <Table size="sm" className="bg-light">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/kiosk.png"
                          style={{ objectFit: "contain", height: "8cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">KIOSK - Self Order POS</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Self Order Kiosk</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/kiosk.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                          />
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>
              {/* QRCode - self Order */}
              <Table size="sm" className="">
                <tr className=" getstated-round ">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/qrcodeselforder.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/qrcodeselforder.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                          />
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>
            </>
            <Showcase></Showcase>
            <Pricing></Pricing>
          </div>
        </div>
      </>
    );
  }
}
