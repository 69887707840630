import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CartContext from "./CartContext";
import { Audio, Watch, ThreeDots } from "react-loader-spinner";

export class LoadSpinner extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      callback: this.props.callback,
    };
  }
  customstyle = {
    content: {
      ...Modal,
    },
    overlay: { zIndex: 3000 },
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.callback();
  };
  render() {
    return (
      <>
        <Modal
          size="sm"
          width="20pt"
          className="spinnermodal-backdrop spinnermodalcenter "
          // style={this.customStyles}
          show={this.state.open}
          // backdrop="static"
          keyboard={false}
          style={{ opacity: "50 !important" }}

          // onHide={this.handleClose}
        >
          <Modal.Body
            className="loadspinnermodalalign "
            style={{
              backgroundColor:
                this.context.theme == ""
                  ? "bg-white"
                  : this.context.theme.mainNavBarBackgroundColor,
              color: this.context.theme.mainNavBarTextColor,
            }}
          >
            <div>
              {/* <Bars
                height="80"
                width="350"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> */}
              {/* <Watch
                height="80"
                width="350"
                radius="48"
                color="#4fa94d"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              /> */}
              {this.context.store == "" ? (
                ""
              ) : (
                <>
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      this.context.storeSettings.storeLogoFileName +
                      this.context.store.storageSasToken
                    }
                    //className="logohover invertimage m-0 p-0"
                    className={"logohover m-0 p-0  loadspinnermodalalign"}
                    style={{
                      cursor: "pointer",
                      color: this.context.theme.mainNavBarTextColor,
                      width:
                        this.context.theme.storeLogoImageWidth == undefined
                          ? "2cm"
                          : this.context.theme.storeLogoImageWidth + "cm",
                      backgroundColor: "transparant",
                      filter: "invert(" + this.context.theme.invertLogo + "%)",
                    }}
                  />
                  <br />
                </>
              )}
              {/* Please wait! Loading... */}
              <div className="loadspinnermodalalign">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  // color="#4fa94d"
                  color={this.context.theme.mainNavBarTextColor}
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
