import React, { Component, useState } from "react";
import { useContext } from "react";
import CartContext from "./CartContext";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Showcase } from "./Showcase";
import { DemoLinks } from "./Home";
import { CommonModules } from "./CommonModules";
import { ECommerceFeatures, ECommercePricing } from "./Pricing";
import { DigitalMarketingPricing } from "./Pricing";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export class ECommerce extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      stores: [
        {
          name: "restaurant.png",
          title: "Restaurant Shop",
          url: "https://restaurant.elasticcart.com",
        },
        {
          name: "grocery.png",
          title: "Grocery Store",
          url: "https://supermarket.elasticcart.com",
        },
        {
          name: "fashion.png",
          title: "Fashion Shop",
          url: "https://fashion.elasticcart.com",
        },
        {
          name: "fruitsandvegetables.png",
          title: "Fruits and Vegetables Shop",
          url: "https://fruitsandvegetables.elasticcart.com",
        },
        {
          name: "furniture.png",
          title: "Furniture Shop",
          url: "https://furniture.elasticcart.com",
        },
        {
          name: "jewellery.png",
          title: "Jewellery Shop",
          url: "https://jewellery.elasticcart.com",
        },
        {
          name: "electronics.png",
          title: "Electroinics Shop",
          url: "https://electronics.elasticcart.com",
        },
        {
          name: "mobile.png",
          title: "Mobile Shop",
          url: "https://mobileshop.elasticcart.com",
        },
        {
          name: "floral.png",
          title: "Floral Store",
          url: "https://floral.elasticcart.com",
        },
        {
          name: "bakery.png",
          title: "Bakery Shop",
          url: "https://bakery.elasticcart.com",
        },
        {
          name: "books.png",
          title: "Books Shop",
          url: "https://books.elasticcart.com",
        },
        {
          name: "Pharma.png",
          title: "Pharmacy Shop",
          url: "https://pharma.elasticcart.com",
        },
        {
          name: "sarees.png",
          title: "Boutique and Sarees Shop",
          url: "https://sarees.elasticcart.com",
        },
        {
          name: "sports.png",
          title: "Sports Shop",
          url: "https://sports.elasticcart.com",
        },
        {
          name: "meatshop.png",
          title: "Meat Shop",
          url: "https://restaurant.elasticcart.com",
        },
        {
          name: "vegrestaurant.png",
          title: "Veg Restaurant Shop",
          url: "https://vegrestaurant.elasticcart.com",
        },
        {
          name: "machinery.png",
          title: "Tools and Machinery Store",
          url: "https://machinery.elasticcart.com",
        },
        {
          name: "aquiarium.png",
          title: "Aquiarium Shop",
          url: "https://aquiarium.elasticcart.com",
        },
      ],
    };
  }
  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* ECommerce */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0" id="ecommerceSection">
                  eCommerce / Online Store
                </h2>
              </div>

              <span className="text-muted my-1">
                <p>
                  ElasticCART is a powerful ECOMMERCE platform which provides
                  stunning and custimizable themes along with PREMIUM features
                  to quickly onboard and create Online Stores.
                </p>
                <p>
                  ElasticCART has instance eCommerce solution for all Retail
                  Business and we can onboard your Online-Store in few miniutes.
                </p>
                <p>
                  Our eCommerce sites are SUPER FAST and Hosted on Microsoft
                  Azure Cloud.
                </p>
                <p>
                  We also support CUSTOM CHANGES as per your Business demand.
                </p>
              </span>

              {/* Carousel  */}
              <div class="col-lg-8  border-dark">
                <Carousel
                  fade
                  activeIndex={this.state.index}
                  onSelect={this.handleSelect}
                >
                  {this.state.stores.map((m) => (
                    <Carousel.Item interval={1000}>
                      <div className="d-block border bg-secondary p-5 CornersRounded text-center">
                        <h1 className="text-white">{m.title}</h1>
                        <a href={m.url} className="m-0 p-0" target="_blank">
                          <img
                            className="d-block "
                            src={"images/" + m.name}
                            alt="First slide"
                            style={{
                              width: "100%",

                              cursor: "pointer",
                              objectFit: "contain",
                            }}
                          />
                        </a>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>

              {/* Features */}
              <div class="col-lg-4">
                <h4 class="text-body mb-2 my-2">
                  <h5>Features</h5>
                  <h6 className="text-black " style={{ fontFamily: "inherit" }}>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Free SSL and Custom domain </li>
                      <li>B2C, B2B, C2C, D2C and Enterprise Business</li>
                      <li>2-Factor OTP Authentication via SMS/Email </li>
                      <li>Premium Themes and Custom Themes</li>
                      <li>Payment Gateway integration, COD and Manual UPI</li>
                      <li>Discounts and Wish Lists</li>
                      <li>Vlogs, Blogs Integration</li>
                      <li>Single and Multi-variant Products Support</li>
                      <li>Multiple Images and Videos for every Product </li>
                      <li>AI based Fuzzy logic product Search </li>
                      <li>Easy Checkout as Guest / Registered user</li>
                      <li>Multiple Placeholders for Advertisments</li>
                      <li>Multi-level Category and Single Product Views</li>
                      <li>Product Groups & Deals View</li>
                      <li>Power Filters & Sorting of Products</li>
                      <li>Smart Cart & Product suggestions</li>
                      <li>Refund/Reward/Promotional Coupons. </li>
                      <li>Royalty Points Management. </li>
                      <li>Tax Management, Out-Of-Stock Notification</li>
                      <li>Delivery Cost Management</li>
                      <li>Customer Orders and Dashboard</li>
                      <li>Invoices, email and WhatsApp notification</li>
                      <li>Orders Tracking , Notification on abandoned Cart</li>
                      <li>
                        ERP Modules
                        <ul>
                          <li>Purchase Management</li>
                          <li>Suppliers Management</li>
                          <li>Raw Materials Inventory Management</li>
                          <li>Manufacturing/Production </li>
                          <li>Sales & Leads Management</li>
                          <li>Finance</li>
                          <li>Reports and Analytics</li>
                        </ul>
                      </li>
                    </ul>
                  </h6>
                </h4>
              </div>
              <br />
              <br />
              {/* <h2 className="text-primary text-center">
                This is a powerful Marketing and Selling Platform !
              </h2>
              <br />
              <br />
              <div className="text-center py-3">
                <h2 style={{ color: "blue" }}>Start an Online Store Today!</h2>
              </div>
              <br />
              <br />
              <h2 className="text-primary text-center">
                Increase your Sales with a Premium Online Store !
              </h2> */}

              <CommonModules></CommonModules>

              <Showcase></Showcase>
            </div>
            <br />
            <br />
            <ECommercePricing></ECommercePricing>
            <br />
            <br />
            <DigitalMarketingPricing></DigitalMarketingPricing>
            <br />
            <br />
            <ECommerceFeatures></ECommerceFeatures>
          </div>
        </div>
      </>
    );
  }
}
