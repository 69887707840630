import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Products } from "./Products";
import { Showcase } from "./Showcase";
import { Pricing } from "./Pricing";
import { Services } from "./Home";

export class Solutions extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="container-fluid py-5">
          <div class="container">
            <div class="row gx-4">
              <div class="col-lg-12">
                <div class="border-start border-5 border-primary ps-5 mb-5">
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h2 class="display-4 text-uppercase mb-0">Solutions</h2>
                </div>
                <h4 class="text-body mb-2">
                  <h4>
                    ElasticCART is a powerful Cloud ERP, POS and eCommerce
                    platform. We have implemented our solution to Restaurant,
                    Super Market, Cake Retail , Poultry, Jewellery, Fashions,
                    Clothing, Footware, Photography, Floral, Books, Mobile
                    Phones, Electronics businesses.
                  </h4>
                </h4>
              </div>
              <br></br>
              <h5 className="text-secondary">
                <ul>
                  <li>
                    It runs on Web Browsers and any Device(PC/Phone/Tablet).
                    It's not a Mobile App.
                  </li>
                  <li>Stunning Store Themes</li>
                  <li>Super Speed and Elastic</li>
                  <li>Runs on IOS/Android/Windows</li>
                  <li>Advanced Analytics & Dashboards</li>
                  <li>Bulk upload of Products and Images</li>
                  <li>Free Subdomain and SSL</li>
                </ul>
              </h5>
            </div>
          </div>
        </div>
        <Showcase></Showcase>
        <Products></Products>
        {/* <Services></Services> */}
      </>
    );
  }
}
