import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class Pricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Pricing Plan Start  */}
        <div class=" py-2 ">
          <div className="p-1" style={{ margin: "20px" }}>
            <div class="border-start border-5 border-primary ps-2 mb-2">
              <h6 class="text-primary text-uppercase">Index</h6>
              <h2 class="display-5  mb-0">PRICING</h2>
            </div>
            Click the Links below to see pricing plans of each Product!
            Call/WHATSAPP US for a best Quotation.
            <h6 class="text-dark">
              <ul
                class="text-dark"
                role="list"
                style={{
                  listStyleType: "none",
                  fontFamily: "Roboto",
                  fontWeight: "bolder",
                }}
              >
                <li data-icon="👉" className="py-2 p-0 m-0">
                  <a href="#pos" style={{ color: "blue" }}>
                    {" "}
                    POS Billing Software (CLOUD -Point Of Sale) & ERP
                  </a>
                </li>
                <li className="py-2" data-icon="👉">
                  <a href="#ecommerceshared" style={{ color: "blue" }}>
                    {" "}
                    ECommerce (Online-Store) & ERP
                  </a>
                </li>
                <li className="py-2" data-icon="👉">
                  <a href="#digitalmarketing" style={{ color: "blue" }}>
                    {" "}
                    Digital Marketing Service
                  </a>
                </li>

                <li className="py-2" data-icon="👉">
                  <a href="#qrcode" style={{ color: "blue" }}>
                    {" "}
                    QRCode - Self Ordering POS
                  </a>
                </li>

                <li className="py-2" data-icon="👉">
                  <a href="#kiosksec" style={{ color: "blue" }}>
                    {" "}
                    KIOSK - Self Ordering POS
                  </a>
                </li>

                <li className="py-2" data-icon="👉">
                  <a href="#scm" style={{ color: "blue" }}>
                    {" "}
                    ERP - Enterprise Resource Planning / SCM Supply Chain
                    Management
                  </a>
                </li>
              </ul>
            </h6>
            <div class="col-lg-4  py-0">
              <div class="d-inline-flex align-items-center">
                <div className="blink_me">
                  <i class="bi bi-phone-vibrate fs-1 text-primary me-3 "></i>
                </div>
                <div className="text-start blink_me">
                  <b>call/text us +91-9663617620</b>
                </div>
              </div>
            </div>
          </div>

          <div className="p-1" style={{ margin: "20px" }}>
            <PosPricing></PosPricing>
            <br />
            <br />
            {/* <ECommercePricing></ECommercePricing> */}
            <ECommerceFeatures></ECommerceFeatures>
            <br />
            <br />
            <DigitalMarketingPricing></DigitalMarketingPricing>
            <br />
            <br />
            <KioskPricing></KioskPricing>
            <br />
            <br />
            <QRCodePricing></QRCodePricing>
            <br />
            <br />
            <SCMPricing></SCMPricing>
          </div>
        </div>
      </>
    );
  }
}

export class PosPricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div id="pos">
          <div
            class="border-start border-5 border-primary ps-5 mb-5"
            // style="max-width: 600px;"
          >
            <h6 class="text-primary text-uppercase">Pricing Plan</h6>
            <h2 class="display-6  mb-0">
              POS Billing Software (CLOUD - Point Of Sale) & ERP (Enterprise
              Resource Planning)
            </h2>
          </div>
          <h5>
            Click here to see the <a href="/POS"> POS Features</a>
          </h5>
          <div class="row g-2 text-center ">
            {/* <div class="col-lg-2 border bg-light">
              <div className="bg-primary text-white py-2">
                <h3 class="text-white">FREE</h3>
                <h6 class="mb-2">FREE</h6>
              </div>
              <div class="text-start p-0 mb-0 bg-light text-black">
                <h4>FREE</h4>
                <ul>
                  <li>
                    Data retention - 30 Days
                    <li>Single User Access</li>
                    <li>20 Transactions Per day</li>
                    <li>Single Store</li>
                    <li>unlimited Products</li>
                    <li>PC & Mobile Friendly</li>
                    <li>Inventory Management</li>
                    <li>Basic reports</li>
                  </li>
                </ul>
              </div>
            </div> */}
            <div class="col-lg-3 border bg-light">
              <div className="bg-primary text-white py-2">
                <h3 class="text-white">Basic</h3>
                <h6 class="mb-2">Starter</h6>
              </div>
              <div class="text-start p-0 mb-0 bg-light text-black">
                {/* <h4>₹7,500 per Year</h4> */}
                <ul>
                  <li>
                    Data retention - 6 Months
                    <li>Single User Access</li>
                    <li>200 Transactions Per day</li>
                    <li>Single Store</li>
                    <li>unlimited Products</li>
                    <li>BARCODE Reader Integration</li>
                    <li>Weighing Machine Integration</li>
                    <li>Payment Gateway Integration</li>
                    <li>PC & Mobile Friendly</li>
                    <li>Inventory Management</li>
                    <li>CRM, Coupons Management</li>
                    <li>Basic reports</li>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 border bg-light">
              <div className="bg-primary text-white py-2">
                <h3 class="text-white">Standard</h3>
                <h6 class="mb-2">Best Try!</h6>
              </div>
              <div class="text-start p-0 mb-0 bg-light text-black">
                {/* <h4>₹12,500 per Year</h4> */}
                <ul>
                  <li>Data retention - 12 Months</li>
                  <li>1 Users Access</li>
                  <li>Unlimited Transactions</li>
                  <li>Single Store</li>
                  <li>Unlimited Products</li>
                  <li>PC & Mobile Friendly</li>
                  <li>Inventory Management</li>
                  <li>CRM, Coupons Management</li>
                  <li>Purchase Modules</li>
                  <li>Orders Workflow Management</li>
                  <li>Payment Gateway Integration</li>
                  <li>In-Store/TakeAway Orders</li>
                  <li>Standard reports & Analytics</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 border bg-light">
              <div className="bg-primary text-white py-2">
                <h3 class="text-white">Professional</h3>
                <h6 class="mb-2">Best Choice</h6>
              </div>
              <div class="text-start p-0 mb-0 bg-light text-black">
                {/* <h4 className="">₹24,000 per Year</h4> */}
                <ul>
                  <li>Data retention - 2 Years</li>
                  <li>
                    2 User Access &#8377; Additional users 7500/- per year
                  </li>
                  <li>Unlimited Transactions Per day Per User</li>
                  <li>Single Store</li>
                  <li>Unlimited Products</li>
                  <li>PC & Mobile Friendly</li>
                  <li>Inventory Management</li>
                  <li>CRM, Coupons Management</li>
                  <li>Purchase Modules</li>
                  <li>Orders Workflow Management</li>
                  <li>Payment Gateway Integration</li>
                  <li>In-Store/TakeAway/HomeDelivery Orders</li>
                  <li>Advanced reports & Analytics</li>
                </ul>
                <b>Restaurant Business</b>
                <ul>
                  <li>Captain App</li>
                  <li>Kitchen Display System(KDS)</li>
                  <li>Receipe Management</li>
                  <li>Kitchen & RawMaterials Management</li>
                  <li>QRCode based Table Order</li>
                  <li>Swiggy/Zomato Orders</li>
                  <li>Digital Menu</li>
                  <li>GREETING Menu</li>
                  <li>Delivery App</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 border bg-light">
              <div className="bg-primary text-white py-2">
                <h3 class="text-white">PREMIUM</h3>
                <h6 class="mb-2">Way To Go!</h6>
              </div>
              <div class="text-start p-0 mb-0 bg-light text-black">
                {/* <h4 className="">₹48,000 per Year</h4> */}
                <ul>
                  <li>Data retention - 3 Years</li>
                  <li>Upto 5 Users access. Additional users 7500/- per year</li>
                  <li>Unlimited Transactions Per day Per User</li>
                  <li>upto 2 Stores</li>
                  <li>Unlimited Products</li>
                  <li>PC & Mobile Friendly</li>
                  <li>Inventory Management</li>
                  <li>CRM, Coupons Management</li>
                  <li>Purchase Modules</li>
                  <li>Orders Workflow Management</li>
                  <li>Payment Gateway Integration</li>
                  <li>In-Store/TakeAway/HomeDelivery Orders</li>
                  <li>Advanced reports & Analytics</li>
                </ul>
                <b>Restaurant Business</b>
                <ul>
                  <li>Captain App</li>
                  <li>Kitchen Display System(KDS)</li>
                  <li>Receipe Management</li>
                  <li>Kitchen & RawMaterials Management</li>
                  <li>QRCode based Table Order</li>
                  <li>Swiggy/Zomato Orders</li>
                  <li>Digital Menu</li>
                  <li>GREETING Menu</li>
                  <li>Delivery App</li>
                </ul>
              </div>
            </div>
          </div>
          <div class=" text-center">
            <a
              href=""
              class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
            >
              Call/Text +91-9663617620 for Best Quote!
            </a>
          </div>
        </div>
      </>
    );
  }
}

export class ECommercePricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          class="border-start border-5 border-primary ps-5 mb-5"
          // style="max-width: 600px;"
          id="ecommerceshared"
        >
          <h6 class="text-primary text-uppercase">
            SaaS - Shared Hosting Plan
          </h6>
          <h2 class="display-6  mb-0">
            ECommerce / Online-Store (Shared Hosting)
          </h2>
          <br />
          <h3>
            <div className=" ">
              <Link to="/ecommerce" className=" blink_me">
                {" "}
                <u>Click here to see ECommerce Solution </u>
              </Link>
            </div>
          </h3>
        </div>
        <div class="row g-2 text-center ">
          <div class="col border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">FREE</h3>
              <h6 class="mb-2">Start Free</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      FREE Store
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [No Annual Cost]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>3.5% Transaction Fee</b>
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;4,500</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>

          <div class="col border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">STARTER</h3>
              <h6 class="mb-2">Starter</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 1,999 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [billed annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>1.85% Transaction Fee</b> above 1 Lakh /Month Sale
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;4,500</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
          <div class="col border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">STANDARD</h3>
              <h6 class="mb-2">Popular</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 3,999 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [billed annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>1.85% Transaction Fee</b> above 2 Lakhs /Month Sale
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;5,500</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
          <div class="col border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">PROFESSIONAL</h3>
              <h6 class="mb-2">Best Choice</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 5,999 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [billed annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>1.85% Transaction Fee</b> above 2 Lakh Sale / Month
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;6,000</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
          <div class="col border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">PREMIUM / ENTERPRISE</h3>
              <h6 class="mb-2">Way To Go</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 8,999 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [billed annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>1.85% Transaction Fee</b> above 2 Lakh Sale / Month
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;8,000</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div
          class="border-start border-5 border-primary ps-5 mb-5"
          // style="max-width: 600px;"
          id="ecommerceindependent"
        >
          <h6 class="text-primary text-uppercase">Independent Hosting</h6>
          <h5 class="display-6  mb-0">
            ECommerce / Online-Store (Independent Hosting)
          </h5>
          <br />
          <h3>
            <div className=" ">
              <Link to="/ecommerce" className=" blink_me">
                {" "}
                <u>Click here to see ECommerce Solution </u>
              </Link>
            </div>
          </h3>
        </div>
        <div class="row g-2 text-center ">
          <div class="col-lg-3 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">STANDARD</h3>
              <h6 class="mb-2">STANDARD</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 6,500 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [Billed Annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>2.49% Transaction Fee </b> > 1 Lakh Sale/month
                    <br />
                    [Billed end of every month]
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Advanced SEO
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Access to Database(DB) and Business APIs
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;8000</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">PROFESSIONAL</h3>
              <h6 class="mb-2">Popular</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 8,500 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [Billed Annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>2.49% Transaction Fee </b> > 2Lakh Sale/month
                    <br />
                    [Billed end of every month]
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Access to Database(DB) and Business APIs
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Advanced SEO
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;10,000</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-3 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">PREMIUM / ENTERPRISE</h3>
              <h6 class="mb-2">Way-to-go</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                <li>
                  <small
                    // class="align-top"
                    style={{ fontSize: "16px" }}
                  >
                    <b>
                      ₹ 12,500 / Month
                      {/* <strike>2500</strike>  */}
                    </b>
                  </small>
                  <br />
                  [Billed Annually]
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <b>2.49% Transaction Fee </b> > 2 Lakh Sale/month
                    <br />
                    [Billed end of every month]
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Advanced SEO
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    Access to Database(DB) and Business APIs
                  </small>
                </li>
                <li>
                  <small class="align-top" style={{ fontSize: "12pt" }}>
                    <i>
                      <b>One-time Basic Setup Fee &#8377;12,000</b>
                    </i>
                  </small>
                </li>
              </ul>
              <div className="text-center ">
                <Link to="/ECommerceFeatures" className=" text-center blink_me">
                  {" "}
                  <u>Click to see Features </u>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class=" text-center">
          <a
            href=""
            class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
          >
            Call/WHATSAPP US !
          </a>
        </div>
        <br />
        <br />
      </>
    );
  }
}

export class ECommerceFeatures extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div>
          <div
            class="border-start border-5 border-primary ps-5 mb-5 "
            style={{ margin: "20px" }}
            // style="max-width: 600px;"
          >
            <h6 class="text-primary text-uppercase">License Plan</h6>
            <h2 class="display-6  mb-0">
              ECommerce (Online-Store) & ERP(Enterprise Resource Planning)
            </h2>
          </div>
          <div class="row g-5">
            <div class="col-lg-3">
              {/* <div class="bg-light text-center pt-5 mt-lg-5"> */}
              <div class="bg-light text-center pt-1 ">
                <div class="text-center bg-primary p-0 mb-0 text-white">
                  <h3 class="text-uppercase text-white">Starter </h3>
                  <h6 class="text-black mb-3">Starter</h6>
                </div>

                <div class="d-flex align-items-center justify-content-between mb-1 border">
                  <span>
                    <ul className="text-start">
                      <li>Standard Cloud Compute - Hosting</li>
                      <li>
                        FREE Custom Domain (1 Year License) with Starter Plan
                      </li>
                      <li>Products unlimited</li>
                      <li>Support-Standard</li>
                      <li>Unlimited Customer Accounts </li>
                      <li>Unlimited Transactions</li>
                      <li>Free Cloud Storage upto 5GB</li>
                      <li>
                        FREE Custom Changes upto 4hrs a Month on Starter Plan
                      </li>
                      <li>Data retention upto 1 Year</li>
                      <li>Single Store </li>
                      <li>Admin Account - 1</li>
                      <li>Contributor accounts - unlimited</li>
                    </ul>
                  </span>
                </div>

                <div class="text-start p-2">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Online ECOMMERCE Store </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Admin Panel Desktop/Mobile Friendly</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Free Sub Domain and SSL</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Product Inventory and Single Warehouse</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Free Software Upgrades</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Standard Product Theme</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span> Email/SMS based OTP Authentication</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Custom Domain Integration</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Manual UPI, Cash On Delivery Payment Method</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Payment Gateway Integration</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between
                    mb-1"
                  >
                    <span>Single & Multi-Variant Products</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Order Workflow Management-Basic</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>CRM & Coupons Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>

                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>SMS & Emails OTP Authentication</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Product Wishlist (or) Save for Later</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Analytics & Reports - Standard</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Power Filters and AI Search</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Restaurant: KDS, CAPTAIN App, QRCode Ordering</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="bg-light text-center pt-1">
                <div class="text-center bg-primary p-0 mb-0 text-white">
                  <h3 class="text-uppercase text-white">Standard</h3>
                  <h6 class="text-black mb-3">Popular</h6>
                </div>

                <p className="border border-secondary bg-light">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>
                      <ul className="text-start">
                        <li>Standard Cloud Compute - Hosting</li>
                        <li>FREE Custom Domain - 1 year license</li>
                        <li>Support-Standard</li>
                        <li>Products unlimited</li>
                        <li>Unlimited Customer Accounts </li>
                        <li>Unlimited Transactions</li>
                        <li>Free Cloud Storage upto 20GB</li>
                        <li>Data retention upto 2 Years</li>
                        <li>Single Store </li>
                        <li>1 Admin Accounts</li>
                        <li>Contributor Account Unlimited</li>
                        <li>1 FREE License Point-Of-Sale users</li>
                      </ul>
                    </span>
                  </div>
                </p>
                <div class="text-start p-2" style={{ fontSize: "12pt" }}>
                  <b>STARTER PLAN + </b>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Standard Order Workflow Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>

                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Similar Product Suggession </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Coupons Management - Standard </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Purchase History and Analytics</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Traffic View</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>WhatsApp Integration support</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Standard Purchase Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Standard Reports & Analytics</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="bg-light text-center pt-1">
                <div class="text-center bg-primary p-0 mb-0 text-white">
                  <h3 class="text-uppercase text-white">Professional</h3>
                  <h6 class="text-black mb-3">Best Choice</h6>
                </div>

                <p className="border border-secondary bg-light">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>
                      <ul className="text-start">
                        <li>Professional Cloud Compute - Hosting</li>
                        <li>FREE Custom Domain - 1 year license</li>
                        <li>Support-Professional</li>
                        <li>Products unlimited</li>
                        <li>Unlimited Customer Accounts </li>
                        <li>Unlimited Transactions</li>
                        <li>Free Cloud HOT Storage upto 100GB</li>
                        <li>FREE Custom Changes upto 16hrs a Month</li>
                        <li>Data retention upto 3 Year</li>
                        <li>Single Stores</li>
                        <li>unlimited Admin Accounts</li>
                        <li>Contributor Account Unlimited</li>
                        <li>2 FREE License Point-Of-Sale users</li>
                        <li>Auto Scale Enabled</li>{" "}
                      </ul>
                    </span>
                  </div>
                </p>
                <div class="text-start p-2" style={{ fontSize: "12pt" }}>
                  <b>STANDARD PLAN + </b>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Multi Ads Placeholders</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Product Videos, VLOG, BLOG </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Advance Order Workflow Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Product Q&A </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Product Ratings and Comments </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer recently viewed Products list </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Feedback </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Reward points management </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Coupons Management - Advanced </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Purchase History and Analytics</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Customer Traffic View</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>WhatsApp Integration support</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Professional & Custom Themes</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Purchase Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>

                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Manufacturing, Sales and Service Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>HRM - Human Resource Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Finance/Accounting Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Restaurant: RawMaterials & Receipe Management</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Advanced Reports & Analytics</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="bg-light text-center pt-1">
                <div class="text-center bg-primary p-0 mb-0 text-white">
                  <h3 class="text-uppercase text-white">
                    PREMIUM / ENTERPRISE
                  </h3>
                  <h6 class="text-black mb-3">Way to go!</h6>
                </div>

                <p className="border border-secondary bg-light">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>
                      <ul className="text-start">
                        <li>Premium Cloud Compute - Hosting</li>
                        <li>FREE Custom Domain - 1 year license</li>
                        <li>Support-Professional</li>
                        <li>Products unlimited</li>
                        <li>Unlimited Customer Accounts </li>
                        <li>Unlimited Transactions</li>
                        <li>Free Cloud HOT Storage upto 200GB</li>
                        <li>FREE Custom Changes upto 24hrs a Month</li>
                        <li>Data retention upto 5 Year</li>
                        <li>Multi/Enterprise Stores upto 3 </li>
                        <li>Admin Accounts - unlimited</li>
                        <li>Products unlimited</li>
                        <li>Contributor Account Unlimited</li>
                        <li>3 FREE License Point-Of-Sale users</li>
                        <li>Auto Scale Enabled</li>
                      </ul>
                    </span>
                    {/* <i class="bi bi-check2 fs-4 text-primary"></i> */}
                  </div>
                </p>
                <div class="text-start p-2" style={{ fontSize: "12pt" }}>
                  <b>PROFESSIONAL PLAN +</b>
                  <div class="d-flex align-items-center justify-content-between mb-1"></div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Custom Payment Gateway Integration </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Custom Themes</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>International Currency Support </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>3rd Platform Integration upto 3</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>WhatsApp Integration support</span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span>Bulk Emails Support </span>
                    <i class="bi bi-check2 fs-4 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class DigitalMarketingPricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          class="border-start border-5 border-primary ps-5 mb-5"
          // style="max-width: 600px;"
          id="digitalmarketing"
        >
          <h6 class="text-primary text-uppercase">Pricing Plan</h6>
          <h2 class="display-6 text-uppercase mb-0">
            Digital Marketing Expert Service
          </h2>
        </div>
        Our Digital Marketing experts will be engaged to work on Promoting your
        Business/Brand on Social Media Marketing Platforms like Google,
        Facebook, Instagram, Twitter, Linkedin and WhatsApp/SMS/Email Marketings
        and WhatsApp Marketing.
        <br />
        <br />
        <b>Scope of Work by Digital Marketing Experts</b>
        <ul>
          <li>
            Business Account Creation on Social Media Platforms like Google,
            Facebook and Instagram, Twitter, Linkedin
          </li>
          <li>Posters & Marketing Content Creation</li>
          <li>WhatsApp/SMS/Email Broadcasting</li>
          <li>
            Run Campaigns on Social Medias Platform like Google, Facebook and
            Instagram, Twitter, Linkedin
          </li>
        </ul>
        <b>Prerequisites</b>
        <ul>
          <li>Client to provide access to Social Media Accounts</li>
          <li>Client to provide Bulk SMS/WhatsApp/Email Account access</li>
          <li>
            Client to Credit Campaign cost on the Social Media Accounts along
            with interested Social media accounts to focus and amount to spend
            for Campaign
          </li>
        </ul>
        <br />
        <div class="row g-2 text-center ">
          <div class="col-lg-4 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">Basic</h3>
              <h6 class="mb-2">starter</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                {/* <li>
                  <b>₹4,500 per Month</b>
                  <br />
                  [billed minimum 6 Months]
                </li> */}
                <li>
                  <b>4hrs of Effort per Week</b>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">Standard</h3>
              <h6 class="mb-2">popular</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                {/* <li>
                  <b>₹8,500 per Month</b>
                  <br />
                  [billed minimum 6 Months]
                </li> */}
                <li>
                  <b>8hrs of Effort per Week</b>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 border bg-light">
            <div className="bg-primary text-white py-2">
              <h3 class="text-white">Professional</h3>
              <h6 class="mb-2">way-to-go</h6>
            </div>
            <div class="text-start p-0 mb-0 bg-light text-black">
              <ul>
                {/* <li>
                  <b>₹12,000 per Month</b>
                  <br />
                  [billed minimum 6 Months]
                </li> */}
                <li>
                  <b>12hrs of Effort per Week</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class=" text-center">
          <a
            href=""
            class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
          >
            Call/WHATSAPP US +91-9663617620 for best Quote!
          </a>
        </div>
      </>
    );
  }
}

export class KioskPricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div
          class="border-start border-5 border-primary ps-5 mb-5"
          id="kiosksec"
        >
          <h6 class="text-primary text-uppercase">Pricing Plan</h6>
          <h2 class="display-6 text-uppercase mb-0">KIOSK POS Software</h2>
          <br />
          <h3>
            <div className=" ">
              <Link to="/Kiosk" className=" blink_me">
                {" "}
                <u>Click here to see Kiosk Solution </u>
              </Link>
            </div>
          </h3>
        </div>
        We only provide KIOSK POS Software and will help to Purchase suitable
        KIOSK System as per Business Needs. <br />
        The Cost Kiosk System will vary based on Size of the System, Operating
        System, Specifications and Style. We will engage our KIOSK System Vendor
        to purchase suitable System .
        <br />
        Our KIOSK Software suitable to All Retail Business to deploy in-Store
        for Self-Ordering and Digital Catelog Purposes. <br />
        <br />
        Click this link to see the features{" "}
        <a href="/kiosk">
          <u>Kiosk Features</u>
        </a>
        <br />
        <br />
        {/* <ul className="bg-light py-4">
          <li>
            <h5>
              First User &#8377;2,500 <strike> &#8377;3,500 </strike> Per Month{" "}
            </h5>
          </li>
          <li>
            <h5>
              Additional User &#8377;1,500 <strike> &#8377;2,500 </strike> Per
              Month{" "}
            </h5>
          </li>
          <li>
            <h5>One-time Basic Setup Fee &#8377;5,500</h5>
          </li>
        </ul> */}
        <div class=" text-center">
          <a
            href=""
            class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
          >
            Call/WHATSAPP US +91-9663617620 for best Quote!
          </a>
        </div>
      </>
    );
  }
}

export class QRCodePricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="border-start border-5 border-primary ps-5 mb-5" id="qrcode">
          <h6 class="text-primary text-uppercase">Pricing Plan</h6>
          <h2 class="display-6 text-uppercase mb-0">
            QRCode based Self-Ordering ECommerce
          </h2>
          <br />
          Click this link to see the features{" "}
          <a href="/POS">
            <u>QRCode based Self Ordering</u>
          </a>
          <br />
          <br />
          {/* <ul className="bg-light py-4">
            <li>
              <h5>
                &#8377;9,000 <strike> &#8377;24,000 </strike>&nbsp; Per Year Per
                Shop
              </h5>
            </li>
            <li>
              <h5>2.5% Transaction Fee</h5>
            </li>
            <li>
              <h5>One-time Basic Setup Fee &#8377;5,500</h5>
            </li>
          </ul> */}
          <div class=" text-center">
            <a
              href=""
              class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
            >
              Call/WHATSAPP US +91-9663617620 for best Quote!
            </a>
          </div>
        </div>
      </>
    );
  }
}

export class SCMPricing extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="border-start border-5 border-primary ps-5 mb-5" id="scm">
          <h6 class="text-primary text-uppercase">Pricing Plan</h6>
          <h2 class="display-6 text-uppercase mb-0">
            Supply Chain Management / ERP
          </h2>
          <br />
          Click this link to see the features{" "}
          <a href="/SupplyChain">
            <u>Supply Chain Management</u>
          </a>
        </div>
        <ul className="bg-light py-4">
          <li>Manufacturing/Production Workflow Management</li>
          <li>Procurement Management</li>
          <li>Raw Materials Inventory Management</li>
          <li>Suppliers Management</li>
          <li>Product Purchases Management</li>
          <li>Multi-warehouse Management</li>
          <li>Product Estimation</li>
          <li>ECommerce / Online Store - B2C, B2B, D2C</li>
          <li>Billing POS [Point-Of-Sale]</li>
          <li>KIOSK Solution</li>
          <li>Orders Management</li>
          <li>Logistics Management</li>
          <li>Product Servicing Management</li>
          <li>CRM - Customer Relationship Management</li>
          <li>Leads Management</li>
          <li>HRM-Human Resource Management</li>
          <li>Finance Management</li>
          <li>Dashboard, Reports & Analytics</li>
        </ul>
        <div class=" text-start">
          <a
            href=""
            class="btn btn-primary text-uppercase py-2 px-4 my-3 text-center"
          >
            Call/WHATSAPP US +91-9663617620 for best Quote!
          </a>
        </div>
      </>
    );
  }
}
