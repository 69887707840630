import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  const [storeId, SetStoreId] = useState(
    "7CD3CED1-5EA2-4FCE-8C73-FEF6248B8AB8"
  );
  // const [commonSettings, setCommonSettings] = useState([]);

  // const GetStore = () => {
  //   fetch(process.env.REACT_APP_API + "Sales/GetStore/" + storeId)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status == 400) {
  //       } else {
  //         document.title = data.name;
  //         setStore(data);
  //       }
  //     })
  //     .catch((error) => {
  //       alert("GetStores Error:: " + error);
  //     });
  // };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {}, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };
  const StoreMyIpAddress = () => {
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //this.setState({ myClientIpAddress: data.IPv4 });
        var res = axios
          .post(
            process.env.REACT_APP_API +
              "Consumers/StoreClientIpaddress/" +
              storeId +
              "/" +
              data.ip
          )
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {
            console.log("StoreMyIpAddress error");
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <CartContext.Provider
      value={{
        storeId,
        StoreMyIpAddress,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
