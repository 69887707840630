import React, { Component, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsFillHeartFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import Select from "react-select";

import "../CSS/textanimation.css";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import CartContext from "./CartContext";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstload: false,
    };
  }

  componentDidMount() {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.context.StoreMyIpAddress();
    }
  }
  componentWillUnmount() {}
  unload(e) {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        {/* <Helmet>
          <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet> */}
        <a
          class="whats-app "
          href={"https://wa.me/" + 9880582728}
          target="_blank"
        >
          <i class="bi bi-whatsapp my-float"></i>
        </a>
        {/* Top bar */}
        <div class="container-fluid border-bottom d-none d-lg-block">
          <div class="row gx-0">
            <div class="col-lg-4 text-center py-2">
              <div class="d-inline-flex align-items-center">
                <i class="bi bi-geo-alt fs-1 text-primary me-3"></i>
                <div class="text-start">
                  <h5 class="text-uppercase mb-1">Our Office</h5>
                  <span>
                    SANTREZA IT Solutions PVT Ltd{" "}
                    <a href="https://www.santreza.com" target="_blank">
                      (www.santreza.com)
                    </a>
                    , Bangalore, Karnataka, India - 560100.{" "}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center border-start border-end py-2">
              <div class="d-inline-flex align-items-center">
                <i class="bi bi-envelope-open fs-1 text-primary me-3"></i>
                <div class="text-start">
                  <h5 class="text-uppercase mb-1">Email Us</h5>
                  <span>support@ElasticCART.com</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center py-2">
              <div class="d-inline-flex align-items-center">
                <i class="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                <div class="text-start">
                  <h5 class="text-uppercase mb-1">Call Us for Demo</h5>
                  <span>+91-96636 17620</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NAV bar */}
        <nav class="navbar navbar-expand-lg bg-white navbar-light shadow-sm py-3 py-lg-0 px-3 px-lg-0 ">
          <a href="/home" class="navbar-brand ms-lg-5 border-0">
            <h2 class="p-0 m-0 text-dark">
              <img src="/images/elasticlogo.jpg" height={40}></img>
              <b>ElasticCART.com </b>
              <h6 className="bg-primary text-white text-center p-0 m-0">
                Cloud ERP, ECOMMERCE, POS
                {/* <span className="text-white">CLOUD Based</span> */}
              </h6>
            </h2>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0 mx-1">
              <a href="/Home" class="nav-item nav-link active">
                Home
              </a>

              {/* <a href="/Products" class="nav-item nav-link">
                Products
              </a> */}
              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Products
                </a>
                <div class="dropdown-menu m-0 " style={{ fontSize: "20px" }}>
                  <a href="/ECOMMERCE" class="dropdown-item ">
                    ECOMMERCE / Online Store
                  </a>
                  <a href="/POS" class="dropdown-item">
                    POS Billing Software -[CLOUD - POINT OF SALE]
                  </a>
                  <a href="/Kiosk" class="dropdown-item">
                    KIOSK Software for Retail
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Supply Chain Management (SCM)
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Enterprise Resource Planning (ERP)
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Customer Relationship Management (CRM)
                  </a>
                </div>
              </div>
              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Solutions
                </a>
                <div class="dropdown-menu m-0 " style={{ fontSize: "20px" }}>
                  <a href="/Restaurant" class="dropdown-item ">
                    Restaurant Solution
                  </a>
                  <a href="/Retail" class="dropdown-item">
                    Retail & WholeSale Solution
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Supply Chain / ERP Solution
                  </a>
                </div>
              </div>
              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Industry
                </a>
                <div class="dropdown-menu m-0 " style={{ fontSize: "20px" }}>
                  <a href="/Retail" class="dropdown-item ">
                    Retail & WholeSale
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Manufacturing & Service
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Automotive
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Customer Relationship
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Human Resource
                  </a>
                  <a href="/SupplyChain" class="dropdown-item">
                    Logistics
                  </a>
                </div>
              </div>
              <a href="/showcase" class="nav-item nav-link">
                Showcases
              </a>
              {/* <a href="/themes" class="nav-item nav-link">
                Themes
              </a> */}

              <a href="/Pricing" class="nav-item nav-link">
                Pricing
              </a>

              <a
                href="https://wa.me/9880582728"
                class="nav-link  bg-primary text-white px-0 ms-lg-6 "
              >
                <div class="d-inline-flex align-items-center">
                  <i class="bi bi-phone-vibrate fs-1 text-warning me-1"></i>
                  <div class="text-center m-1">
                    <h6 class="text-uppercase mb-1">Call us for Demo</h6>
                    <span>+91-96636 17620</span> <br />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </nav>
        {/* // Back to Top */}
        <a href="#" class="btn btn-primary py-3 fs-4 back-to-top">
          <i class="bi bi-arrow-up"></i>
        </a>
      </>
    );
  }
}
