import React, { Component, useState } from "react";
import { useContext } from "react";
import CartContext from "./CartContext";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Showcase } from "./Showcase";
import { DemoLinks } from "./Home";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  Accordion,
  Table,
  Card,
  Carousel,
  Badge,
  Nav,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export class CommonModules extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="container my-5">
          {/* Inventory */}
          <h6>
            Our ERP Modules comes with wide range of features those are
            essential to manage your Business with ease.
          </h6>

          {window.innerWidth > 1200 ? (
            <>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Product Inventory Management & In/Out-of-Stock
                        Management
                      </Card.Title>
                      <Zoom>
                        <img
                          // variant="top"
                          src="/images/ERP/inventory.jpg"
                          // height="200px"
                          className="img-hover-zoom"
                          style={{ width: "100%" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Easily manage your Inventory from anywhere with any
                        PC/Phone/Tablet. Bulk upload from an excel file to
                        Inventory Database.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Product Purchases & Suppliers Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/Purchase.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage Suppliers, Purchase Request(PR) and Purchase
                        Orders(PO). Through these modules Instock of Product
                        Inventory managed.
                      </Card.Text>
                      {/* <Button variant="primary">Learn more...</Button> */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Orders, Delivery and Returns Workflow Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/orders.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Online or Table Orders are pushed into powerful Digital
                        Queue to address priority basis. The entire Order
                        management workflow ensure, Received, Packaged,
                        Delivered and Payment received.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Raw Materials & Suppliers Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/rawmaterials.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Easily upload Products items from an excel file to
                        Inventory Database. Powerful app to manage the Price,
                        Discounts, Availability, Category, Groups, Product
                        Images/Videos, etc., Inventory can be managed with
                        PC/Tablet/Phone.
                      </Card.Text>
                      {/* <Button variant="primary">Learn more...</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Manufacturing and Production Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/production.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage Production worlflow with ease. With this module
                        utilize Rawmaterials and manage production workflow and
                        procedures to produce Product.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>ECOMMERCE</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/ecommerce.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        We offers premium ECommerce solution which provide
                        stunning themes and custom themes. Our ECOMMERCE
                        solution is a 100% marketing and selling platform. It
                        provides many placeholders to display ADs. It comes with
                        Fuzzy-Search capablities. We integrate with Secured
                        Payment gateways along with COD payment options. Our
                        solution is user-friendly and super fast.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Point-Of-Sale(POS)</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/pos.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        We offers Cloud based and responsive Point-Of-Sale which
                        runs on any device. Our POS works on Offline/Online
                        Mode. It can be integrated with any universal barcode
                        reader, POS Printer and Scaling Machine.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Post-Sale Servicing</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/servicing.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Our Product Servicing modules provide very effective and
                        productive ways to manage Service operation with ease.
                        It offers key modules to manage Customers, Service
                        Request, Tasks Inventory Management, Booking, Workflow
                        automation, Invoice and Delivery process modules.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Supply Chain Management</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/supplychain.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage end-to-end supply chain process that includes
                        Product Inventory, Procurement, Manufacturing, Sales,
                        Service and Support.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Customer Relationship Management(CRM)
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/crm.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        With Powerful CRM Dashboard, get insight about regular
                        customers, guest and sales by period. Its a marketing
                        platform as well to collect customer information and to
                        followup and attend customer questions and queries.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Human Resource Management - HRM</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/hrm.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Finance Management</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/finance.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage day-to-day Accounting process with ease including
                        employee payrolls. It offers with Receivable(AR),
                        Payable(AP), Revenues and Expenses Management, Asset
                        Management, and Leder Modules. Generate advanced reports
                        and anaytics as and when management required.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Dashboards, Analytics & Reports</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/reports.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Product Inventory Management & In/Out-of-Stock
                        Management
                      </Card.Title>
                      <Zoom>
                        <img
                          // variant="top"
                          src="/images/ERP/inventory.jpg"
                          // height="200px"
                          className="img-hover-zoom"
                          style={{ width: "100%" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Easily manage your Inventory from anywhere with any
                        PC/Phone/Tablet. Bulk upload from an excel file to
                        Inventory Database.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Product Purchases & Suppliers Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/Purchase.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage Suppliers, Purchase Request(PR) and Purchase
                        Orders(PO). Through these modules Instock of Product
                        Inventory managed.
                      </Card.Text>
                      {/* <Button variant="primary">Learn more...</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Orders, Delivery and Returns Workflow Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/orders.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Online or Table Orders are pushed into powerful Digital
                        Queue to address priority basis. The entire Order
                        management workflow ensure, Received, Packaged,
                        Delivered and Payment received.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Raw Materials & Suppliers Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/rawmaterials.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Easily upload Products items from an excel file to
                        Inventory Database. Powerful app to manage the Price,
                        Discounts, Availability, Category, Groups, Product
                        Images/Videos, etc., Inventory can be managed with
                        PC/Tablet/Phone.
                      </Card.Text>
                      {/* <Button variant="primary">Learn more...</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Manufacturing and Production Management
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/production.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage Production worlflow with ease. With this module
                        utilize Rawmaterials and manage production workflow and
                        procedures to produce Product.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>ECOMMERCE</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/ecommerce.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        We offers premium ECommerce solution which provide
                        stunning themes and custom themes. Our ECOMMERCE
                        solution is a 100% marketing and selling platform. It
                        provides many placeholders to display ADs. It comes with
                        Fuzzy-Search capablities. We integrate with Secured
                        Payment gateways along with COD payment options. Our
                        solution is user-friendly and super fast.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Point-Of-Sale(POS)</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/pos.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        We offers Cloud based and responsive Point-Of-Sale which
                        runs on any device. Our POS works on Offline/Online
                        Mode. It can be integrated with any universal barcode
                        reader, POS Printer and Scaling Machine.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Post-Sale Servicing</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="images/ERP/servicing.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Our Product Servicing modules provide very effective and
                        productive ways to manage Service operation with ease.
                        It offers key modules to manage Customers, Service
                        Request, Tasks Inventory Management, Booking, Workflow
                        automation, Invoice and Delivery process modules.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Supply Chain Management</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/supplychain.JPG"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage end-to-end supply chain process that includes
                        Product Inventory, Procurement, Manufacturing, Sales,
                        Service and Support.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>
                        Customer Relationship Management(CRM)
                      </Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/crm.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        With Powerful CRM Dashboard, get insight about regular
                        customers, guest and sales by period. Its a marketing
                        platform as well to collect customer information and to
                        followup and attend customer questions and queries.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Human Resource Management - HRM</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/hrm.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Finance Management</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/finance.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        Manage day-to-day Accounting process with ease including
                        employee payrolls. It offers with Receivable(AR),
                        Payable(AP), Revenues and Expenses Management, Asset
                        Management, and Leder Modules. Generate advanced reports
                        and anaytics as and when management required.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="border-0 bg-light p-3">
                  <Card className="p-2">
                    <Card.Body>
                      <Card.Title>Dashboards, Analytics & Reports</Card.Title>
                      <Zoom>
                        <Card.Img
                          variant="top"
                          src="/images/ERP/reports.jpg"
                          style={{ objectFit: "contain" }}
                        />
                      </Zoom>
                      <Card.Text>
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </>
    );
  }
}
