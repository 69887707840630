import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import CartContext from "./Components/Marketing/CartContext";
import React, { createContext, useContext, useEffect, useState } from "react";
import Navigation from "./Components/Marketing/Navigation";
import { Layout } from "./Components/Layout";
import { Footer, Home } from "./Components/Marketing/Home";
import { Products } from "./Components/Marketing/Products";
import { ECommerceFeatures, Pricing } from "./Components/Marketing/Pricing";
import { Showcase } from "./Components/Marketing/Showcase";
import { Themes } from "./Components/Marketing/Themes";
import { Solutions } from "./Components/Marketing/Solutions";
import { Restaurant } from "./Components/Marketing/Restaurant";
import { Retail } from "./Components/Marketing/Retail";
import { SupplyChain } from "./Components/Marketing/SupplyChain";
import { Theme } from "react-select";
import { ECommerce } from "./Components/Marketing/ECommerce";
import { ERP } from "./Components/Marketing/ERP";
import { POS } from "./Components/Marketing/POS";
import { Kiosk } from "./Components/Marketing/Kiosk";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./Components/Marketing/CartContext";
import { VisitorsCount } from "./Components/Marketing/VisitorsCount";

function App() {
  const [windowDimension, setWindowDimension] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
    // if (window.innerWidth <= 640) {
    if (window.innerWidth <= 1100) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Container fluid className="m-0 p-0 w-100">
      {isMobile ? (
        <>
          <CartProvider>
            <BrowserRouter>
              <Navigation />
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="solutions" element={<Solutions />} />
                  <Route path="Products" element={<Products />} />
                  <Route path="ECommerce" element={<ECommerce />} />
                  <Route
                    path="ECommerceFeatures"
                    element={<ECommerceFeatures />}
                  />
                  <Route path="POS" element={<POS />} />
                  <Route path="Kiosk" element={<Kiosk />} />
                  <Route path="ERP" element={<ERP />} />
                  <Route path="themes" element={<Themes />} />
                  <Route path="showcase" element={<Showcase />} />
                  <Route path="Pricing" element={<Pricing />} />
                  <Route path="Restaurant" element={<Restaurant />} />
                  <Route path="Retail" element={<Retail />} />
                  <Route path="SupplyChain" element={<SupplyChain />} />
                  <Route path="VisitorsCount" element={<VisitorsCount />} />
                  <Route path="*" element={<Home />} />
                </Route>
              </Routes>
              <Footer></Footer>
            </BrowserRouter>
          </CartProvider>
        </>
      ) : (
        <CartProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="solutions" element={<Solutions />} />
                <Route path="Products" element={<Products />} />
                <Route path="ECommerce" element={<ECommerce />} />
                <Route
                  path="ECommerceFeatures"
                  element={<ECommerceFeatures />}
                />
                <Route path="POS" element={<POS />} />
                <Route path="Kiosk" element={<Kiosk />} />
                <Route path="ERP" element={<ERP />} />
                <Route path="themes" element={<Themes />} />
                <Route path="showcase" element={<Showcase />} />
                <Route path="Pricing" element={<Pricing />} />

                <Route path="Restaurant" element={<Restaurant />} />
                <Route path="Retail" element={<Retail />} />
                <Route path="SupplyChain" element={<SupplyChain />} />
                <Route path="VisitorsCount" element={<VisitorsCount />} />

                <Route path="*" element={<Home />} />
              </Route>
            </Routes>
            <Footer></Footer>
          </BrowserRouter>
        </CartProvider>
      )}
    </Container>
  );
}

export default App;
