import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class ERP extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Supply Chain & ERP Solution  */}
        <div class="container-fluid py-5" id="erpSection">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b>Supply Chain / ERP</b>
                </h2>
              </div>
              <span class="text-body " className="text-muted ">
                ElasticCART supports end-to-end Supply Chain management
                workflows such as Raw Material Management, Procurement
                Management, Manufacturing, Servicing, Logistics, and Selling
                (B2B,B2C). Our solution is cloud-based and responsive.
                &nbsp;&nbsp;&nbsp;
                <a href="/SupplyChain">
                  <b style={{ fontSize: "16px" }}>click here learn more...</b>
                </a>
              </span>

              <div class="col-lg-6  border-dark my-2">
                <a href="img/scmsolution.png" target="_blank">
                  <img
                    class="w-100  rounded border"
                    src="img/scmsolution.png"
                    style={{ objectFit: "contain" }}
                  />
                </a>
                <br></br>
                <br></br>
                <br></br>
                <a href="images/adminportal.png" target="_blank">
                  <img
                    class="w-100  rounded"
                    src="images/adminportal.png"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </div>
              <div class="col-lg-6 my-2">
                <h5 className=" " style={{ fontFamily: "inherit" }}>
                  {" "}
                  Features
                </h5>
                <h6 className="text-muted " style={{ fontFamily: "inherit" }}>
                  <ul style={{ lineHeight: "1.5em", marginBottom: "15px" }}>
                    <li>Raw Materials Inventory Management</li>
                    <li>Supplier and Procurement Management(PR & PO)</li>
                    <li>Manufacturing and Production Management</li>
                    <li>Multi Warehouse Management</li>
                    <li>Product Inventory Management</li>
                    <li>Purchase Modules and Resell Management</li>
                    <li>
                      Order Processing(Packaging, Labeling, Delivery, Shipping
                      and Returns Management
                    </li>
                    <li>
                      smart-POS, KIOSK POS, QRCODE based self ordering POS
                    </li>
                    <li>eCommerce B2C and B2B</li>
                    <li>Cloud Storage </li>
                    <li>Ads Management</li>
                    <li>CRM-Customer Relationship Management</li>
                    <li>Coupons and Loyalty Points Management</li>
                    <li>Customer Ratings and Surveys</li>
                    <li>
                      Email, WhatsApp, SMS integration Marketing on demand
                      integration
                    </li>
                    <li>Logistics Integration with Shiprocket</li>
                    <li>Dashboard, Analytics and Reports</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
