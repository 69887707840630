import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export class Products extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* ECommerce */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              {/* <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px;">
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src="img/about.jpg" style="object-fit: cover;">
                </div>
              </div> */}
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0" id="ecommerceSection">
                  <b>eCommerce B2C, B2B, D2C, C2C</b>
                </h2>
              </div>
              <span className="text-muted my-3">
                <p>
                  {/* ElasticCART is user-friendly and comes with premium features
                  to build eCommerce Applications. It shall be used for B2C,
                  B2B,D2C and C2C scenarios. It comes with stunning themes and
                  customizable. Premium theme integrations are supported on
                  demand.  */}
                  ElasticCART provides eCommerce platform to build Online Stores
                  with premium features which are easily customizable as per
                  business need. It's hosted on Microsoft Azure Cloud and
                  provides excellent performance for Online Stores. With the
                  help of our eCommerce platform, we can provide solutions for
                  B2C, B2B, D2C and C2C businesses. ElasticCart offer stunning
                  themes which are easily customizable as per your interest.
                  <br />
                  Click here to see{" "}
                  <a href="/showcase" target="_blank">
                    Online Store Show cases for all Retail Biz
                  </a>
                  <br />
                  <br />
                  We can bring up your Online-Store within 10 minutes from your
                  Inventory.
                </p>
                <p>
                  It’s secured with SSL and hosted on Microsoft Cloud and super
                  fast. Our platform integrated with Payment Gateways and other
                  3rd party Plugins for Order, Delivery and Payment Managements.
                </p>
              </span>
              <div class="col-lg-6  border-dark">
                <img
                  class="w-100 h-100 rounded"
                  src="images/customerportal.png"
                  style={{ objectFit: "contain" }}
                />
              </div>

              <div class="col-lg-6">
                <h4 class="text-body mb-2 my-2">
                  <h5>Features</h5>
                  <h6 className="text-black " style={{ fontFamily: "inherit" }}>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Responsive Design & 2-Factor Authentication</li>
                      <li>Quick product Search & Add to Cart</li>
                      <li>Easy Checkout as Guest / Registered user</li>
                      <li>Multiple Placeholders for Advertising</li>
                      <li>Multi-level Category and Product Views</li>
                      <li>Product Groups & Deals Category View</li>
                      <li>Multiple images per Product & Description</li>
                      <li>Fuzzy Logic algorithm Search</li>
                      <li>Power Filters & Sorting of Products</li>
                      <li>Smart Cart & Product suggestions</li>
                      <li>Discounts and Wish Lists</li>
                      <li>Secured RazorPay Payment Gateway, COD</li>
                      <li>Multiple Delivery Address support.</li>
                      <li>Refund/Reward/Promotional Coupons. </li>
                      <li>Tax Management, Out-Of-Stock Notification</li>
                      <li>Customer Orders and Dashboard</li>
                      <li>Invoices & email notification</li>
                      <li>Orders Tracking , Notification on abandoned Cart</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* Smart-POS */}
        <div class="container-fluid py-3" id="posSection">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b>Smart-POS</b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                <p>
                  The smart-POS is built with premium and user-friendly features
                  for quick billing and orders management, and you can easily
                  access it from any device, phone, tablet, or PC. Restaurant
                  POS comes with Point-of-Sale (POS) and Table Orders (TO)
                  modules. Also provides dashboards and table/room orders as
                  queue priority. It works great with any Barcode Reader, POS
                  printers and Weighing machines.
                </p>
              </span>
              <br></br>
              <div class="col-lg-6  border-dark my-5">
                <a href="images/pos.png" target="_blank">
                  <img
                    class="w-100 h-100 rounded"
                    src="images/pos.png"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </div>

              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black ">
                    <h4>front-desk POS Features</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Access anytime anywhere with Phone/Tablet/PC</li>
                      <li>Barcode Reading & Weighing Maching Integration</li>
                      <li>Responsive Design & 2-Factor Authentication</li>
                      <li>
                        Quick Billing - paper/paperless Invoice genreation
                      </li>
                      <li>Multi POS Sessions</li>
                      <li>
                        search by product SKU, short cut codes, product
                        category, fuzzy logic search engine, AI-based void
                        recognition search
                      </li>
                      <li>Coupons, Loyalty Points</li>
                      <li> Delivery Charges and Tax Modules</li>
                      <li>Support 5 POS Sessions </li>
                      <li>
                        Delivery Methods: In-Store Purchase, Take-Away, and Home
                        Delivery
                      </li>
                      <li>
                        Integrated with payment methods like cash, QR code, COD,
                        or payment gateway
                      </li>
                      <li>
                        Easy inventory management, in/out of stock management
                      </li>
                      <li>
                        Included Purchase Modules like Purchase Requests and
                        Purchase Orders
                      </li>
                      <li>
                        Reports and Dashboards Cash, UPI and RazorPayment
                        Gateway modules
                      </li>
                    </ul>
                    <h4>Table Orders(TO)</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Access anytime anywhere with Phone/Tablet/PC</li>
                      <li>Responsive Design & 2-Factor Authentication</li>
                      <li>
                        Quick Billing - paper/paperless Invoice genreation
                      </li>
                      <li>Table Dashboard</li>
                      <li>Code based Product selection</li>
                      <li>Quick Fuzzy Search</li>
                      <li>Voice recognition search</li>
                      <li>Add Custom Items</li>
                      <li>Coupons</li>
                      <li>Order Workflow between Table and Kitchen</li>
                      <li>Secured RazorPay Payment Gateway, COD</li>
                      Cash, UPI and RazorPayment Gateway modules
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* KIOSK-POS */}
        <div class="container-fluid py-3" id="kioskSection">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b>KIOSK POS</b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer KIOSK based custom solution for any Retail business.
                The Kiosk can be used as Digital Catelog/Menu and Self-ordering
                POS. We provide Software solution and setup to onboard KIOSK
                based solution for any business. Call us to learn more!
              </span>
              <br></br>
              <div class="col-lg-6  border-dark  ">
                <img
                  class="w-100 h-100 rounded "
                  src="img/kiosk.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6  ">
                <h4 class="text-body mb-2 my-5">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Digital Catelog/Menu</li>
                      <li>Save Labor Cost by 50%</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time by 50%</li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* QRCODE -POS */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6  mb-0">
                  <b>QRCODE based self order POS</b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer custom QRCODE based self ordering solution for any
                Retail business. Customer can scan your Business QRCODE to see
                Menu of your Products on their own Phone/Tablet for self
                Ordering.
              </span>
              <br></br>
              <div class="col-lg-6  border-dark my-2">
                <img
                  class="w-100 h-100 rounded border"
                  src="img/qrcode.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Digital Catelog/Menu</li>
                      <li>Save Labor Cost</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time </li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* Tabke QRCODE -POS */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6  mb-0">
                  <b>Self Table order with QRCODE </b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer QRCODE based Table Self ordering solution for any
                Restaurant businesses. Customer can scan your unique Table
                QRCODE to see Menu of your Products on their own Phone/Tablet
                for self Ordering. This is a contact less and convenient for
                customers to order self.
              </span>
              <br></br>
              <div class="col-lg-6  border-dark">
                <img
                  class="w-100 h-100 rounded border"
                  src="img/tableqrcode.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Marketing Platform</li>
                      <li>Digital Catelog/Menu & Convenient for Customer</li>
                      <li>Save Labor Cost</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time </li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>

        {/* Supply Chain & ERP Solution  */}
        <div class="container-fluid py-5" id="erpSection">
          <div class="container">
            <div class="row gx-5">
              {/* <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 500px;">
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src="img/about.jpg" style="object-fit: cover;">
                </div>
              </div> */}
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b>Supply Chain / ERP</b>
                </h2>
              </div>
              <span class="text-body " className="text-muted ">
                ElasticCART supports end-to-end Supply Chain management
                workflows such as Raw Material Management, Procurement
                Management, Manufacturing, Servicing, Logistics, and Selling
                (B2B,B2C). Our solution is cloud-based and responsive.
                &nbsp;&nbsp;&nbsp;
                <a href="/SupplyChain">
                  <b style={{ fontSize: "16px" }}>click here learn more...</b>
                </a>
              </span>

              <div class="col-lg-6  border-dark my-2">
                <a href="img/scmsolution.png" target="_blank">
                  <img
                    class="w-100  rounded border"
                    src="img/scmsolution.png"
                    style={{ objectFit: "contain" }}
                  />
                </a>
                <br></br>
                <br></br>
                <br></br>
                <a href="images/adminportal.png" target="_blank">
                  <img
                    class="w-100  rounded"
                    src="images/adminportal.png"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </div>
              <div class="col-lg-6 my-2">
                <h5 className=" " style={{ fontFamily: "inherit" }}>
                  {" "}
                  Features
                </h5>
                <h6 className="text-muted " style={{ fontFamily: "inherit" }}>
                  <ul style={{ lineHeight: "1.5em", marginBottom: "15px" }}>
                    <li>Raw Materials Inventory Management</li>
                    <li>Supplier and Procurement Management(PR & PO)</li>
                    <li>Manufacturing and Production Management</li>
                    <li>Multi Warehouse Management</li>
                    <li>Product Inventory Management</li>
                    <li>Purchase Modules and Resell Management</li>
                    <li>
                      Order Processing(Packaging, Labeling, Delivery, Shipping
                      and Returns Management
                    </li>
                    <li>
                      smart-POS, KIOSK POS, QRCODE based self ordering POS
                    </li>
                    <li>eCommerce B2C and B2B</li>
                    <li>Cloud Storage </li>
                    <li>Ads Management</li>
                    <li>CRM-Customer Relationship Management</li>
                    <li>Coupons and Loyalty Points Management</li>
                    <li>Customer Ratings and Surveys</li>
                    <li>
                      Email, WhatsApp, SMS integration Marketing on demand
                      integration
                    </li>
                    <li>Logistics Integration with Shiprocket</li>
                    <li>Dashboard, Analytics and Reports</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
