import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { DemoLinks } from "./Home";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Features } from "./Products";
import { Showcase } from "./Showcase";
import { Pricing } from "./Pricing";
import { Services } from "./Home";
import { ERP } from "./ERP";
import { CommonModules } from "./CommonModules";

export class SupplyChain extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div class="container-fluid py-5">
          <div class="container">
            <div class="row gx-4 py-2">
              <div class="col-lg-12">
                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h4 class="display-6  mb-0">
                    Modern Supply Chain Management (B2C/B2B/D2C)
                  </h4>
                  <a href="images/supplyChain/overview.png" target="_blank">
                    <img
                      class="w-100 rounded"
                      src="images/supplyChain/overview.png"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </div>
                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h4 class="display-6  mb-0">
                    Business Workflow - Our Solution
                  </h4>
                  <a
                    href="images/supplyChain/supplychainworkflow.png"
                    target="_blank"
                  >
                    <img
                      class="w-100 rounded"
                      src="images/supplyChain/supplychainworkflow.png"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <CommonModules></CommonModules>
            <>
              {/* Raw Materials */}
              {/* <Table size="sm" className="bg-light">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/retail/rawmaterials.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">
                          Raw Materials, Supplier and Procurement Management
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily manage Suppliers, Procurement Processes and
                          Inventory.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Raw Materials, Supplier and Procurement Management
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/retail/rawmaterials.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily manage Suppliers, Procurement Processes and
                          Inventory.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* Manufacturing/Production Management */}
              {/* <Table size="sm" className="bg-light">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Manufacturing/Production & Estimation management
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily manage Production process and Manage Product
                          Specification and Production estimation.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/supplyChain/production.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Manufacturing/Production & Estimation management
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/supplyChain/production.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily manage Production process and Manage Product
                          Specification and Production estimation.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* Inventory */}
              {/* <Table size="sm" className="bg-light">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/inventory.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">
                          Product Inventory and Warehouse Management
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily upload Products items from an excel file to
                          Inventory Database. Powerful app to manage the Price,
                          Discounts, Availability, Category, Groups, Product
                          Images/Videos, etc., Inventory can be managed with
                          PC/Tablet/Phone.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Product Inventory and Warehouse Management
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/inventory.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily upload Products items from an excel file to
                          Inventory Database. Powerful app to manage the Price,
                          Discounts, Availability, Category, Groups, Product
                          Images/Videos, etc., Inventory can be managed with
                          PC/Tablet/Phone.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* Purchase */}
              {/* <Table size="sm" className="">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Product Purchases & In/Out-of-Stock Management
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Suppliers, Purchase Request(PR) and Purchase
                          Orders(PO). Through these modules Instock of Product
                          Inventory managed.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/retail/Purchases.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Product Purchases & In/Out-of-Stock Management
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/retail/Purchases.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Suppliers, Purchase Request(PR) and Purchase
                          Orders(PO). Through these modules Instock of Product
                          Inventory managed.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* eCommerce */}
              {/* <Table size="sm" className="bg-light">
                <tr class="">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/supplyChain/furnitureecommerce.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>

                      <td width="60%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Store - B2C, B2B, D2C
                        </h2>
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <li>
                            Online Stores are powerful platform for selling and
                            to reach broader customers. It's branding. We would
                            create an online Store under your brand name (domain
                            address) where customers/Sellers can order Products
                            Online.
                          </li>
                          <li>
                            Your online restaurant would be integrated with
                            Payment gateway and Cash-On-Delivery features.
                            Online Store supports over 100 beautiful themes and
                            layouts. unlimited customizable themes.
                          </li>
                          <li>
                            Once the order is received, You could manage the
                            order and deliver it to the recipient yourself.
                            Customers or third-party delivery services such as
                            Dunzo and Shiprocket.
                          </li>
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Store - B2C, B2B, D2C
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/customerportal.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <li>
                            Online Stores are powerful platform for selling and
                            to reach broader customers. It's branding. We would
                            create an online Store under your brand name (domain
                            address) where customers/Sellers can order Products
                            Online.
                          </li>
                          <li>
                            Your online restaurant would be integrated with
                            Payment gateway and Cash-On-Delivery features.
                            Online Store supports over 100 beautiful themes and
                            layouts. unlimited customizable themes.
                          </li>
                          <li>
                            Once the order is received, You could manage the
                            order and deliver it to the recipient yourself.
                            Customers or third-party delivery services such as
                            Dunzo and Shiprocket.
                          </li>
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* POS */}
              {/* <Table size="sm" className="border-0">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Smart POS [Point-Of-Sale]
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Direct Store Purchase, Store Pickup Orders, and
                          Home Delivery orders with ease and user-friendly POS.
                          POS is a Front-end and Backend is our Order Management
                          system to manage packaging and delivery. The POS
                          provides Insight about billing, orders and queues
                          along with detailed Dashboard. POS shall be integrated
                          with Printers, Barcode Scanner, Weighing Machine, and
                          Email system for paperless Invoice.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/Pos.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Smart POS [Point-Of-Sale]
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/Grocery/Pos.png"
                          style={{ objectFit: "contain", height: "4cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage Direct Store Purchase, Store Pickup Orders, and
                          Home Delivery orders with ease and user-friendly POS.
                          POS is a Front-end and Backend is our Order Management
                          system to manage packaging and delivery. The POS
                          provides Insight about billing, orders and queues
                          along with detailed Dashboard. POS shall be integrated
                          with Printers, Barcode Scanner, Weighing Machine, and
                          Email system for paperless Invoice.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* KIOSK */}
              {/* <Table size="sm" className="bg-light">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/kiosk.png"
                          style={{ objectFit: "contain", height: "8cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">KIOSK - Self Order POS</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Self Order Kiosk</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/kiosk.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                          />
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}

              {/* QRCode - self Order */}
              {/* <Table size="sm" className="">
                <tr className=" getstated-round ">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/qrcodeselforder.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/qrcodeselforder.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                          />
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table> */}
            </>
            {/* Orders  Management */}
            {/* <Table size="sm" className="bg-light">
              <tr>
                {window.innerWidth > 1200 ? (
                  <>
                    <td width="40%">
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/Ordersdetails.png"
                        style={{ objectFit: "contain", height: "7cm" }}
                      />
                    </td>
                    <td width="60%">
                      <h2 className="text-start">Orders Management</h2>
                      <h6
                        className="text-muted"
                        style={{ textalign: "justify" }}
                      >
                        Online or Table Orders are pushed into powerful Digital
                        Queue to address priority basis. The entire Order
                        management workflow ensure, Received, Packaged,
                        Delivered and Payment received.
                      </h6>
                    </td>
                  </>
                ) : (
                  <>
                    <td width="60%">
                      <h2 className="text-start">Orders Management</h2>
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/Ordersdetails.png"
                        style={{ objectFit: "contain", height: "4cm" }}
                      />
                      <h6
                        className="text-muted"
                        style={{ textalign: "justify" }}
                      >
                        Online or Table Orders are pushed into powerful Digital
                        Queue to address priority basis. The entire Order
                        management workflow ensure, Received, Packaged,
                        Delivered and Payment received.
                      </h6>
                    </td>
                  </>
                )}
              </tr>
            </Table> */}
            {/* CRM */}
            {/* <Table size="sm">
              <tr>
                {window.innerWidth > 1200 ? (
                  <>
                    <td width="60%">
                      <h2 className="text-start">CRM</h2>
                      <h6
                        className="text-muted"
                        style={{ textAlign: "justify" }}
                      >
                        With Powerful CRM Dashboard, get insight about regular
                        customers, guest and sales by period. Its a marketing
                        platform as well to collect customer information and to
                        followup and attend customer questions and queries.
                      </h6>
                    </td>
                    <td width="40%">
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/crm.png"
                        style={{ objectFit: "contain", height: "7cm" }}
                      />
                    </td>
                  </>
                ) : (
                  <>
                    <td width="60%">
                      <h2 className="text-start">CRM</h2>
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/crm.png"
                        style={{ objectFit: "contain", height: "4cm" }}
                      />
                      <h6
                        className="text-muted"
                        style={{ textAlign: "justify" }}
                      >
                        With Powerful CRM Dashboard, get insight about regular
                        customers, guest and sales by period. Its a marketing
                        platform as well to collect customer information and to
                        followup and attend customer questions and queries.
                      </h6>
                    </td>
                  </>
                )}
              </tr>
            </Table> */}
            {/* ERP-Dashboard */}
            {/* <Table size="sm" className="bg-light">
              <tr>
                {window.innerWidth > 1200 ? (
                  <>
                    <td width="40%">
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/admindashboard.png"
                        style={{ objectFit: "contain", height: "7cm" }}
                      />
                    </td>
                    <td width="60%">
                      <h2 className="text-start">ERP-Dashboard</h2>
                      <h6
                        className="text-muted"
                        style={{ textAlign: "justify" }}
                      >
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </h6>
                    </td>
                  </>
                ) : (
                  <>
                    <td width="60%">
                      <h2 className="text-start">ERP-Dashboard</h2>
                      <img
                        class="w-100 rounded"
                        src="images/Grocery/admindashboard.png"
                        style={{ objectFit: "contain", height: "4cm" }}
                      />
                      <h6
                        className="text-muted"
                        style={{ textAlign: "justify" }}
                      >
                        ERP/Admin Dashboard provides insight about Sales,
                        Customers, Orders including packaging and delivery.
                      </h6>
                    </td>
                  </>
                )}
              </tr>
            </Table> */}
            {/* <DemoLinks></DemoLinks> */}
            <Pricing></Pricing>
          </div>
        </div>
      </>
    );
  }
}
