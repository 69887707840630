import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Features } from "./Products";
import { Showcase } from "./Showcase";
import {
  DigitalMarketingPricing,
  ECommercePricing,
  KioskPricing,
  PosPricing,
  Pricing,
} from "./Pricing";
import { Services } from "./Home";
import { Helmet } from "react-helmet";

export class Restaurant extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-4 py-1">
              <div class="col-lg-12">
                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h2 class="display-5  mb-0"> Modern Restaurant Solution</h2>
                  <div class="w-100 p-2 bg-light">
                    <h5>
                      {" "}
                      ElasticCART is ALL-IN-ONE solution to any Restaurant
                      Business. It has advanced next-generation features and
                      hosted on Cloud.
                    </h5>
                    <a
                      href="images/restaurant/restaurantoverview.png"
                      target="_blank"
                    >
                      <img
                        class="w-100 rounded"
                        src="images/restaurant/restaurantoverview.png"
                        style={{ objectFit: "contain" }}
                      />
                    </a>
                  </div>
                </div>

                <div>
                  <h6 class="text-primary text-uppercase">Solution</h6>
                  <h2 class="display-5  mb-0"> Modules and Solution</h2>
                  <div class="w-100 p-2 bg-light">
                    <a href="images/restaurant/overview.png" target="_blank">
                      <img
                        class="w-100 rounded"
                        src="images/restaurant/overview.png"
                        style={{ objectFit: "contain" }}
                      />
                    </a>
                  </div>
                </div>

                <br />
                <div>
                  <h6 class="text-primary ">Solution</h6>
                  <h2 class="display-5  mb-0">
                    {" "}
                    Restaurant Workflow - Our Solution{" "}
                  </h2>
                  <div class="w-100 p-2 bg-light">
                    <a
                      href="images/restaurant/restaurant workflow.png"
                      target="_blank"
                    >
                      <img
                        class="w-100 rounded"
                        src="images/restaurant/restaurant workflow.png"
                        style={{ objectFit: "contain" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <>
              {/* ecommerce */}
              <Table size="sm" className="">
                {window.innerWidth > 1200 ? (
                  <>
                    <tr class="">
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/onlinestore.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>

                      <td width="100%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Restaurant
                        </h2>
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          We would create an online restaurant under your brand
                          name (domain address) where customers can order food.
                          Online. Your online restaurant would be integrated.
                          with Payment gateway and Cash-On-Delivery features.
                          Online Restaurant supports over 100 beautiful themes
                          and layouts. unlimited customizable themes. Once the
                          order is received, You could manage the order and
                          deliver it to the recipient yourself. Customers or
                          third-party delivery services such as Dunzo. 3rd party
                          Delivery integration is an optional feature, as is the
                          Ondemand feature.
                        </h6>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr class="">
                      <td width="100%" className="">
                        <h2 className="text-start">
                          eCommerce / Online Restaurant
                        </h2>
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/onlinestore.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                        <h6
                          class="d-flex justify-content-evenly"
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          We would create an online restaurant under your brand
                          name (domain address) where customers can order food
                          Online. Your online restaurant would be integrated.
                          with Payment gateway and Cash-On-Delivery features.
                          Online Restaurant supports over 100 beautiful themes
                          and layouts. unlimited customizable themes. Once the
                          order is received, You could manage the order and
                          deliver it to the recipient yourself. Customers or
                          third-party delivery services such as Dunzo. 3rd party
                          Delivery integration is an optional feature, as is the
                          Ondemand feature.
                        </h6>
                      </td>
                    </tr>
                  </>
                )}
              </Table>

              {/* point of sale */}
              <Table size="sm" className="border-0">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Point-Of-Sale - POS</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage dine-in, take-away, and home delivery orders
                          with ease and a user-friendly POS system. POS is
                          front-end, and backend is Our KDS (Kichen Display
                          System), which manages food preparation, packaging,
                          and delivery, is both front-end and back-end. We have
                          integration with Zomato/Swiggy as well. The POS offers
                          billing, order, and queue insight, as well as a
                          detailed dashboard. For paperless invoices, the POS
                          system must be integrated with printers, barcode
                          scanners, and an email system.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/pos.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%">
                        <h2 className="text-start">Point-Of-Sale - POS</h2>
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/pos.png"
                          style={{ objectFit: "contain", height: "5cm" }}
                        />
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Manage dine-in, take-away, and home delivery orders
                          with ease and a user-friendly POS system. POS is
                          front-end, and backend is Our KDS (Kichen Display
                          System), which manages food preparation, packaging,
                          and delivery, is both front-end and back-end. We have
                          integration with Zomato/Swiggy as well. The POS offers
                          billing, order, and queue insight, as well as a
                          detailed dashboard. For paperless invoices, the POS
                          system must be integrated with printers, barcode
                          scanners, and an email system.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* KIOSK */}
              <Table size="sm">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/kiosk.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">KIOSK - Self Order POS</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Self Order Kiosk</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/kiosk.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          To present customers with a digital Menu on larget
                          screen Kiosk instead of printouts/booklet where
                          Customer can order for "take away" or to engage at
                          Restaurant about the Products. Digital menus are
                          customizable with chef's recommendation, today's
                          special, Discounts and Top Selling Products and so on!
                          It's a Marketing and Sales platform where Customer can
                          select and purchased with UPI/CARD.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* QRCode - self Order */}
              <Table size="sm">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/qrcodeselforder.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/qrcodeselforder.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          Modern approach to self-order on a Cafe/Restaurant for
                          Dine-in or take-away. We offer custom QRCODE based
                          self ordering solution for any Retail business.
                          Customer can scan your Business QRCODE to see Menu of
                          your Products on their own Phone/Tablet for self
                          Ordering
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* QRCode - Table Order */}
              <Table size="sm">
                <tr className="border-0">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/qrcodetableorder.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Table Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Modern approach to self-order from Table on a
                          Cafe/Restaurant for Dine-in or take-away. This is a
                          contactless approach. We offer QRCODE based Table Self
                          ordering solution for any Restaurant businesses.
                          Customer can scan your unique Table QRCODE to see Menu
                          of your Products on their own Phone/Tablet for self
                          Ordering. This is a contact less and convenient for
                          customers to order self.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          QRCODE based - Table Self Order POS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/qrcodetableorder.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          Modern approach to self-order from Table on a
                          Cafe/Restaurant for Dine-in or take-away. This is a
                          contactless approach. We offer QRCODE based Table Self
                          ordering solution for any Restaurant businesses.
                          Customer can scan your unique Table QRCODE to see Menu
                          of your Products on their own Phone/Tablet for self
                          Ordering. This is a contact less and convenient for
                          customers to order self.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* Digital Menu */}
              <Table size="sm">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Digital Menu</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          To present customers with a digital Menu instead of
                          printouts/booklet where Customer can order for "take
                          away" or to engage at Restaurant about the Products.
                          Digital menus are customizable with chef's
                          recommendation, today's special, Discounts and Top
                          Selling Products and so on! It's a Marketing and Sales
                          platform where Customer can select and purchased with
                          UPI/CARD.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 "
                          src="images/restaurant/digitalmenu.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Digital Menu</h2>
                        <h6
                          className="text-muted "
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 "
                            src="images/restaurant/digitalmenu.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          To present customers with a digital Menu instead of
                          printouts/booklet where Customer can order for "take
                          away" or to engage at Restaurant about the Products.
                          Digital menus are customizable with chef's
                          recommendation, today's special, Discounts and Top
                          Selling Products and so on! It's a Marketing and Sales
                          platform where Customer can select and purchased with
                          UPI/CARD.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* Captain App */}
              <Table size="sm" className="border-0 text-start">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/tableorders.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                          // className="text-start"
                        />
                      </td>
                      <td width="100%">
                        <h2 className="text-start">
                          Table Orders / Captain App
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Captain App presented with a detailed Dashboard that
                          shows Status of all the Tables in the Restaurant
                          including Occupied/Vacant, Total Ordered Items, Total
                          Sale. Tables are mapped with Waiters to manage the
                          table and to receive orders from customers. Table
                          Orders are smoothly handled with workflow between
                          Table and Kitchen.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%">
                        <h2 className="text-start">
                          Table Orders / Captain App
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/tableorders.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                            // className="text-start"
                          />
                          Captain App presented with a detailed Dashboard that
                          shows Status of all the Tables in the Restaurant
                          including Occupied/Vacant, Total Ordered Items, Total
                          Sale. Tables are mapped with Waiters to manage the
                          table and to receive orders from customers. Table
                          Orders are smoothly handled with workflow between
                          Table and Kitchen.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* KDS */}
              <Table size="sm" className="border-0">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">
                          Kichen Display System-KDS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          KDS is our Orders and Queue insigt Display Screen
                          Where orders are prepared and delivered priority
                          basis. In KDS Table Orders, Take Away, and Home
                          Delivery orders are managed easily.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/kds.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%">
                        <h2 className="text-start">
                          Kichen Display System-KDS
                        </h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/kds.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          KDS is our Orders and Queue insigt Display Screen
                          Where orders are prepared and delivered priority
                          basis. In KDS Table Orders, Take Away, and Home
                          Delivery orders are managed easily.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* Greeting App */}
              <Table size="sm" className="border-0">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/greeting.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">Greeting App</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Greet and manage Customers to Dine-in with waiting
                          queue digital table. With a powerful dashboard
                          identify easily Customer's waiting time and Tables
                          availablity for Dinning.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="100%">
                        <h2 className="text-start">Greeting App</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/greeting.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          Greet and manage Customers to Dine-in with waiting
                          queue digital table. With a powerful dashboard
                          identify easily Customer's waiting time and Tables
                          availablity for Dinning.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* Inventory  */}
              <Table size="sm">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Inventory</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          Easily upload Food Menu items from an excel file to
                          Inventory Database. Powerful app to manage the Price,
                          Discounts, Availability, Category,etc., Inventory can
                          be managed with PC/Tablet/Phone.
                        </h6>
                      </td>
                      <td width="100%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/inventory.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Inventory</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/inventory.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          Easily upload Food Menu items from an excel file to
                          Inventory Database. Powerful app to manage the Price,
                          Discounts, Availability, Category,etc., Inventory can
                          be managed with PC/Tablet/Phone.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* CRM */}
              <Table size="sm">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/crm.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">CRM</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          With Powerful CRM Dashboard, get insight about regular
                          customers, guest and sales by period. Its a marketing
                          platform as well to collect customer information and
                          to followup and attend customer questions and queries.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">CRM</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/crm.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          With Powerful CRM Dashboard, get insight about regular
                          customers, guest and sales by period. Its a marketing
                          platform as well to collect customer information and
                          to followup and attend customer questions and queries.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              {/* Dashboard */}
              <Table size="sm">
                <tr className="border getstated-round bg-light">
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="60%">
                        <h2 className="text-start">ERP-Dashboard</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          ERP/Admin Dashboard provides insight about Sales,
                          Customers, Orders including packaging and delivery.
                        </h6>
                      </td>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/admindashboard.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">ERP-Dashboard</h2>
                        <h6
                          className="text-muted"
                          style={{ textAlign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/admindashboard.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          ERP/Admin Dashboard provides insight about Sales,
                          Customers, Orders including packaging and delivery.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>

              <Table size="sm">
                <tr>
                  {window.innerWidth > 1200 ? (
                    <>
                      <td width="40%">
                        <img
                          class="w-100 rounded"
                          src="images/restaurant/orders.png"
                          style={{ objectFit: "contain", height: "7cm" }}
                        />
                      </td>
                      <td width="60%">
                        <h2 className="text-start">Orders Queue Management</h2>
                        <h6
                          className="text-muted"
                          style={{ textalign: "justify" }}
                        >
                          Online or Table Orders are pushed into powerful
                          Digital Queue to address priority basis. The entire
                          Order management workflow ensure, Received, Packaged,
                          Delivered and Payment received.
                        </h6>
                      </td>
                    </>
                  ) : (
                    <>
                      <td width="60%">
                        <h2 className="text-start">Orders Queue Management</h2>
                        <h6
                          className="text-muted"
                          style={{ textalign: "justify" }}
                        >
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/orders.png"
                            style={{ objectFit: "contain", height: "7cm" }}
                          />
                          Online or Table Orders are pushed into powerful
                          Digital Queue to address priority basis. The entire
                          Order management workflow ensure, Received, Packaged,
                          Delivered and Payment received.
                        </h6>
                      </td>
                    </>
                  )}
                </tr>
              </Table>
            </>
            <div className="py-5">
              <h1>Demo Links</h1>
              {window.innerWidth > 1200 ? (
                <>
                  <Table size="sm" className="border text-center d-flex">
                    <tr className="d-flex" class="d-flex">
                      <td className="border p-2 bg-light">
                        <a
                          href="https://restaurant.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-center">eCommerce</h4>
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/onlinestore.png"
                            style={{ objectFit: "contain", height: "5cm" }}
                            className="p-4"
                          />
                        </a>
                      </td>
                      <td className="border p-2 bg-light">
                        <a
                          href="https://restaurant-pos.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-black">
                            <u>POS & Captain App</u>
                          </h4>
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/pos.png"
                            style={{ objectFit: "contain", height: "5cm" }}
                            className="p-4"
                          />
                        </a>
                      </td>
                      <td className=" p-2 bg-light">
                        <a
                          href="https://restaurant-admin.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-dark">ERP/Admin Portal</h4>
                          <img
                            class="w-100 rounded"
                            src="images/restaurant/admindashboard.png"
                            style={{ objectFit: "contain", height: "5cm" }}
                            className="p-4"
                          />
                        </a>
                      </td>
                    </tr>
                  </Table>
                </>
              ) : (
                <>
                  <Table size="sm" className="border text-start">
                    <tr>
                      <td className="border p-2 bg-light">
                        <a
                          href="https://restaurant.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-start">eCommerce</h4>
                          <img
                            // class="w-100 rounded"
                            src="images/restaurant/onlinestore.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                            className="p-4"
                          />
                        </a>
                        <a
                          href="https://restaurant-pos.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-black">POS & Captain App</h4>
                          <img
                            // class="w-100 rounded"
                            src="images/restaurant/pos.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                            className="p-4"
                          />
                        </a>
                        <a
                          href="https://restaurant-admin.ElasticCART.com"
                          target="_blank"
                        >
                          <h4 className="text-dark">ERP/Admin Portal</h4>
                          <img
                            // class="w-100 rounded"
                            src="images/restaurant/admindashboard.png"
                            style={{ objectFit: "contain", height: "4cm" }}
                            className="p-4"
                          />
                        </a>
                      </td>
                    </tr>
                  </Table>
                </>
              )}
            </div>
            <br />
            <br />
            <Pricing></Pricing>
          </div>
        </div>
      </>
    );
  }
}
