import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { DemoLinks } from "./Home";

export class Showcase extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* Show cases */}

        <div class="container" id="showcases">
          <div class="border-start border-5 border-primary ">
            <h6 class="text-primary text-uppercase">Showcase</h6>
            <h5 class="display-6  mb-0">
              <b>Showcases / Demo </b>
            </h5>
          </div>
          <div class="row g-5">
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://restaurant.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">
                      Restaurant-Multi Cousine
                    </h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/restaurant.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://Supermarket.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Grocery</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/grocery.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-1">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a
                    href="https://fruitsandvegetables.ElasticCART.com"
                    target="_blank"
                  >
                    <h5 class="text-uppercase mb-3">Fruits And Vegetables</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/fruitsandvegetables.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a
                    href="https://vegrestaurant.ElasticCART.com"
                    target="_blank"
                  >
                    <h5 class="text-uppercase mb-3">Veg-Restaurant</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/vegrestaurant.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://fashion.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Fashions</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/fashions.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://sarees.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Sarees</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/sarees.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://mobileshop.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Mobile Shop</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/Mobile.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://Bakery.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Cake Shop</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/bakery.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://jewellery.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Jewellery</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/jewellery.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://Furniture.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Furniture</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/furniture.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://floral.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Floral</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/floral.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://books.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Books</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/books.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://electronics.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Electronics</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/electronics.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://pharma.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Pharmacy</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/pharma.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://Meatshop.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Meat Shop</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/meatshop.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://musicstore.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Music Store</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/musicstore.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://machinery.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Machinery</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/machinery.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://aquarium.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Aquiarium</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/aquiarium.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://nursery.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Plants & Nursery</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/nursery.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://bikencar.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">
                      Bikes & Cars Accessories
                    </h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/bikescarsaccessories.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://footwear.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Footwear</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/footwear.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="service-item bg-light d-flex p-4">
                {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                <div>
                  <a href="https://sports.ElasticCART.com" target="_blank">
                    <h5 class="text-uppercase mb-3">Sports & Gears</h5>
                    <p>
                      <div class="col-lg-12  border-dark">
                        <img
                          class="w-100 h-100 rounded"
                          src="images/sports.png"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <DemoLinks></DemoLinks>
        </div>
      </>
    );
  }
}
