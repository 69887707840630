import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { CommonModules } from "./CommonModules";
import { QRCodePos } from "./POS";
import { TableOrderPos } from "./POS";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { KioskPricing } from "./Pricing";

export class Kiosk extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* KIOSK-POS */}
        <div class="container-fluid py-3" id="kioskSection">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b>KIOSK POS Software</b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer KIOSK based custom solution for any Retail business.
                The Kiosk can be used as Digital Catelog/Menu and Self-ordering
                POS. We provide Software solution and setup to onboard KIOSK
                based solution for any business. Call us to learn more!
              </span>
              <br></br>
              <div class="col-lg-6  border-dark  ">
                <img
                  class="w-100 h-100 rounded "
                  src="img/kiosk.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6  ">
                <h4 class="text-body mb-2 my-5">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Digital Catelog/Menu</li>
                      <li>Save Labor Cost by 50%</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time by 50%</li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <CommonModules></CommonModules>
        <QRCodePos></QRCodePos>
        <TableOrderPos></TableOrderPos>
        <br></br>
        <br></br>
        <KioskPricing></KioskPricing>
      </>
    );
  }
}
