import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { CommonModules } from "./CommonModules";
import { QRCodePricing } from "./Pricing";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { PosPricing } from "./Pricing";
import { KioskPricing } from "./Pricing";

import CartContext from "./CartContext";

export class POS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* Smart-POS */}
        <div class="container-fluid py-3" id="posSection">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6 text-uppercase mb-0">
                  <b> POS Billing Software -[CLOUD - POINT OF SALE] </b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                <p>
                  Our POS is built with premium and user-friendly features for
                  quick billing and orders management. Our POS can be accessed
                  from any device, phone, tablet, or PC. It works great with any
                  Barcode Reader, POS printers and Weighing machines.
                </p>
              </span>
              <br></br>
              <div class="col-lg-6  border-dark my-5">
                <Zoom>
                  <img
                    class="w-100 h-100 rounded"
                    src="images/erp/pos.jpg"
                    style={{ objectFit: "contain" }}
                  />
                </Zoom>
              </div>

              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black my-5">
                    <h4>Features</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Access anytime anywhere with Phone/Tablet/PC</li>
                      <li>Barcode Reading & Weighing Maching Integration</li>
                      <li>Responsive Design & 2-Factor Authentication</li>
                      <li>
                        Quick Billing - paper/paperless Invoice genreation
                      </li>
                      <li>Multi POS Sessions</li>
                      <li>
                        search by product SKU, short cut codes, product
                        category, fuzzy logic search engine, AI-based void
                        recognition search
                      </li>
                      <li>Coupons, Loyalty Points</li>
                      <li> Delivery Charges and Tax Modules</li>
                      <li>Support 5 POS Sessions </li>
                      <li>
                        Delivery Methods: In-Store Purchase, Take-Away, and Home
                        Delivery
                      </li>
                      <li>
                        Integrated with payment methods like cash, QR code, COD,
                        or payment gateway
                      </li>
                      <li>
                        Easy inventory management, in/out of stock management
                      </li>
                      <li>
                        Included Purchase Modules like Purchase Requests and
                        Purchase Orders
                      </li>
                      <li>
                        Reports and Dashboards Cash, UPI and RazorPayment
                        Gateway modules
                      </li>
                      <li>
                        ERP Modules
                        <ul>
                          <li>Purchase Management</li>
                          <li>Suppliers Management</li>
                          <li>Raw Materials Inventory Management</li>
                          <li>Manufacturing/Production </li>
                          <li>Sales & Leads Management</li>
                          <li>Finance</li>
                          <li>Reports and Analytics</li>
                        </ul>
                      </li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <CommonModules></CommonModules>
        <QRCodePos></QRCodePos>
        <TableOrderPos></TableOrderPos>
        <br />
        <br />
        <PosPricing></PosPricing>
        <br />
        <br />
        <KioskPricing></KioskPricing>
        <br />
        <br />
        <QRCodePricing></QRCodePricing>
      </>
    );
  }
}

export class QRCodePos extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* QRCODE -POS */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6  mb-0">
                  <b>QRCODE based self order POS</b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer custom QRCODE based self ordering solution for any
                Retail business. Customer can scan your Business QRCODE to see
                Menu of your Products on their own Phone/Tablet for self
                Ordering.
              </span>
              <br></br>
              <div class="col-lg-6  border-dark my-2">
                <img
                  class="w-100 h-100 rounded border"
                  src="img/qrcode.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Digital Catelog/Menu</li>
                      <li>Save Labor Cost</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time </li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class TableOrderPos extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Table QRCODE -POS */}
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-5">
              <div class="border-start border-5 border-primary ps-3 mb-3">
                <h6 class="text-primary text-uppercase">Product & Features</h6>
                <h2 class="display-6  mb-0">
                  <b>Self Table order with QRCODE </b>
                </h2>
              </div>
              <span className="text-muted" style={{ textAlign: "justify" }}>
                We offer QRCODE based Table Self ordering solution for any
                Restaurant businesses. Customer can scan your unique Table
                QRCODE to see Menu of your Products on their own Phone/Tablet
                for self Ordering. This is a contact less and convenient for
                customers to order self.
              </span>
              <br></br>
              <div class="col-lg-6  border-dark">
                <img
                  class="w-100 h-100 rounded border"
                  src="img/tableqrcode.png"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-lg-6">
                <h4 class="text-body mb-2">
                  <h6 className="text-black ">
                    <h4> Benefits</h4>
                    <ul
                      className="text-muted"
                      style={{ lineHeight: "1.5em", marginBottom: "15px" }}
                    >
                      <li>Self Ordering System POS</li>
                      <li>Marketing Platform</li>
                      <li>Digital Catelog/Menu & Convenient for Customer</li>
                      <li>Save Labor Cost</li>
                      <li>Delight Customer and Envy Competitors</li>
                      <li>Improved Customer Satisfaction</li>
                      <li>Reduce POS Queue time </li>
                      <li>Increse Turnover</li>
                    </ul>
                  </h6>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
