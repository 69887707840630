import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { Features } from "./Products";
import { Showcase } from "./Showcase";
import { Pricing } from "./Pricing";
import { Helmet } from "react-helmet";
import { Row, Col, Table, Carousel, Button } from "react-bootstrap";

export class Services extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Services */}
        <div class="container-fluid py-5">
          <div class="container">
            <div
              class="border-start border-5 border-primary ps-5 mb-5"
              // style="max-width: 600px;"
            >
              <h6 class="text-primary text-uppercase">Services</h6>
              <h1 class="display-4 text-uppercase mb-0">
                Our Excellent Services
              </h1>
            </div>
            <div class="row g-5">
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-house display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">8AM-9PM Support</h5>
                    <p>
                      Dedicated team will be assisting and guiding you while
                      onboarding your store and further customization and
                      Configuration requirements.
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-food display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">
                      Dedicated Relation Manager
                    </h5>
                    <p>
                      A Dedicated Manager and single point of contact for your
                      online business will be assigned to you to help on your
                      Online business until you're on own. This might be a
                      ondemand service based on requirement.
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-grooming display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Managed Support</h5>
                    <p>
                      On demand Service to support your Product configuration
                      and updations 24x7. This might be a ondemand service based
                      on requirement.
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-cat display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Onboarding & Migration</h5>
                    <p>
                      We would work closely on onboarding and migration process
                      for easy onboarding and to go live online.
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-grooming display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">Custom Changes</h5>
                    <p>
                      We undertake any requirement to fulfil your business need
                      to provide modifications and customization to your store
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="service-item bg-light d-flex p-4">
                  {/* <i class="flaticon-grooming display-1 text-primary me-4"></i> */}
                  <div>
                    <h5 class="text-uppercase mb-3">
                      New Application/Product development
                    </h5>
                    <p>
                      Apart from Online store, we undertake any new application
                      development requirement and provide quick solution at
                      reasonable cost. Please get in touch with us.
                    </p>
                    {/* <a class="text-primary text-uppercase" href="">
                        Read More<i class="bi bi-chevron-right"></i>
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
