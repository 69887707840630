import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { Products } from "./Products";
import { Showcase } from "./Showcase";
import { Pricing } from "./Pricing";
import { Services } from "./Services";
import Slider from "react-slick";

import { Row, Col, Table, Carousel, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {window.innerWidth <= 1200 ? (
          <Carousel fade onSelect={() => {}}>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile1.png"
                alt="First slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile2.png"
                alt="Second slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile3.png"
                alt="Second slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile4.png"
                alt="Second slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile5.png"
                alt="Second slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimgmobile w-100"
                src="/images/carouselMobile6.png"
                alt="Second slide"
                // style={{ height: window.innerHeight / 3 }}
              />
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel fade onSelect={() => {}}>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel1.png"
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel2.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel3.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel4.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel5.png"
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={1500}>
              <img
                className="d-block carouselimg w-100"
                src="/images/carousel6.png"
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        )}

        <div class="container-fluid py-3">
          <div class="container">
            <div class="row gx-2">
              <div className="m-1 ">
                <h6 style={{ textAlign: "justify" }}>
                  ElasticCART is a next-generation CLOUD based ERP & ECOMMERCE
                  software platform. <br />
                  <br />
                  It provides modern next-gen solution ALL-IN-ONE Software
                  Solution for Retail, Wholesale, Manufacturing, Supply-chain,
                  Logistics and Enterprise businesses. <br />
                  <br />
                  ElastiCart is a proprietary software platform of{" "}
                  <a href="https://santreza.com" target="_blank">
                    www.Santreza.Com
                  </a>
                </h6>
                <br />
                <div className="text-center blink_me">
                  <Button
                    variant="primary"
                    size="lg"
                    className="getstated-round blink_me"
                  >
                    <a
                      href="https://wa.me/9880582728"
                      class=" nav-link  bg-primary text-white px-2 ms-lg-5 text-center p-0 m-0 rounded"
                    >
                      <div class="d-inline-flex align-items-center">
                        <i class="bi bi-phone-vibrate fs-1 text-warning me-3"></i>
                        <div class="text-center">
                          <span className="text-dark">
                            CALL US for Demo <br />
                          </span>
                          +91- 96636 17620
                        </div>
                      </div>
                    </a>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductHightlights></ProductHightlights>
        <BusinessSpecificSolution></BusinessSpecificSolution>
        {/* <Products></Products> */}
        <BenefitsElasticCart></BenefitsElasticCart>
        {/* <Showcase></Showcase> */}
        {/* <DemoLinks></DemoLinks> */}
        {/* <Pricing></Pricing> */}
        <Services></Services>
      </>
    );
  }
}
export class ProductHightlights extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div class="container  py-2 ">
          <div class=" py-5  text-center ">
            <div class="border-start border-5 border-primary ps-5 mb-5 text-start">
              {/* <h6 class="text-primary text-uppercase">Benefits</h6> */}
              <h1 class="display-6 text-uppercase mb-0">
                <b>Products</b>
              </h1>
            </div>
            {window.innerWidth > 1200 ? (
              <>
                <div className="">
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud ERP <br />
                            <span className="text-secondary">
                              {" "}
                              Enterprise Resource Planning
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/erp1.png"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary ">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/ECommerce">
                        <Card className="border-0">
                          <h4 className="my-3">
                            <b>ECOMMERCE - Online Store</b> <br />
                            <span className="text-secondary">
                              (for any Retail Business)
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/ecommerce1.png"
                            // height="200px"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>

                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/POS">
                        <Card className="border-0">
                          <h4 className="my-3">
                            <b>POS Billing Software -[CLOUD - POINT OF SALE]</b>{" "}
                            <br />{" "}
                            <span className="text-secondary">
                              Retail Billing Software
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/pos1.png"
                            // height="200px"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-0 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/Kiosk">
                        <Card className="border-0">
                          <h4 className="my-3">
                            KIOSK Solution for Retail
                            <br />{" "}
                            <span className="text-secondary">
                              Self Ordering System
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/kiosk1.png"
                            // height="200px"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud CRM <br />{" "}
                            <span className="text-secondary">
                              {" "}
                              Customer Relationship
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/crm1.png"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>

                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud SCM <br />{" "}
                            <span className="text-secondary">
                              {" "}
                              Supply Chain Management
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/supplychain1.png"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/ECommerce">
                        <Card className="border-0">
                          <h4 className="">
                            <b>ECOMMERCE - Online Store</b> <br />
                            <span className="text-secondary">
                              (for any Retail Business)
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/ecommerce1.png"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/POS">
                        <Card className="border-0">
                          <h4 className="my-3">
                            <b>POS Billing Software -[CLOUD - POINT OF SALE]</b>{" "}
                            <br />{" "}
                            <span className="text-secondary">
                              Advanced Retail Billing Software
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/pos1.png"
                            // height="200px"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/Kiosk">
                        <Card className="border-0">
                          <h4 className="my-3">
                            KIOSK Software for Retail
                            <br />{" "}
                            <span className="text-secondary">
                              Self Ordering System
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/kiosk1.png"
                            // height="200px"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud CRM <br />{" "}
                            <span className="text-secondary">
                              {" "}
                              Customer Relationship Management
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/crm1.png"
                            // height="200px"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud SCM <br />{" "}
                            <span className="text-secondary">
                              {" "}
                              Supply Chain Management
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/supplychain1.png"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                  <Row className="m-1 p-2 border-0" style={{ fontSize: "8px" }}>
                    <Col className="m-1 p-3 bg-primary border text-center">
                      <a href="/SupplyChain">
                        <Card className="border-0">
                          <h4 className="my-3">
                            Cloud ERP <br />
                            <span className="text-secondary">
                              {" "}
                              Enterprise Resource Planning
                            </span>
                          </h4>
                          <Card.Img
                            variant="top"
                            src="/images/erp1.png"
                            objectFit="contain"
                          />
                          <Card.Body>
                            <Card.Text></Card.Text>
                            <Button variant="primary">Learn more...</Button>
                          </Card.Body>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export class Footer extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <br />
        {/* <!-- Facts Start --> */}
        <div class="container-fluid facts py-2 pt-lg-0">
          <div class="container py-5 pt-lg-0">
            <div class="row gx-0">
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                <div
                  class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px", height: "60px;" }}
                  >
                    <i class="fa fa-users text-primary"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-white mb-0">Happy Clients</h5>
                    <Row>
                      <Col>
                        <h1 class="text-white mb-0" data-toggle="counter-up">
                          350
                        </h1>
                      </Col>
                      <Col>
                        <h1 class="text-white mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                <div
                  class="bg-light shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-check text-white"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-primary mb-0">Projects Done</h5>
                    <Row>
                      <Col>
                        <h1 class="mb-0" data-toggle="counter-up">
                          375
                        </h1>
                      </Col>
                      <Col>
                        <h1 class=" mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                <div
                  class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-award text-primary"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-white mb-0">Experience</h5>
                    <Row>
                      <Col>
                        <h1 class="text-white mb-0" data-toggle="counter-up">
                          25
                        </h1>
                      </Col>
                      <Col>
                        <h1 class="text-white mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Testimonial></Testimonial> */}
        <Testimonial1></Testimonial1>
        <ClientsSlider></ClientsSlider>
        {/* Footer Start */}
        <div class="container-fluid bg-light mt-5 py-5">
          <div class="container pt-5">
            <div class="row g-5">
              <div class="col-lg-3 col-md-6">
                <h5 class="text-uppercase border-start border-5 border-primary ps-3 mb-4">
                  Get In Touch
                </h5>
                <p class="mb-4"></p>
                <p class="mb-2">
                  <i class="bi bi-geo-alt text-primary me-2"></i>SANTREZA IT
                  Solutions Private Limited, #230, Kammasandra Road, Electronic
                  City, Bangalore, KA, India 560100.
                </p>
                <p class="mb-2">
                  <i class="bi bi-envelope-open text-primary me-2"></i>
                  support@ElasticCART.com
                </p>
                <p class="mb-0">
                  <i class="bi bi-telephone text-primary me-2"></i>
                  +91-9880582728
                </p>
              </div>
              <div class="col-lg-3 col-md-6">
                <h5 class="text-uppercase border-start border-5 border-primary ps-3 mb-4">
                  Quick Links
                </h5>
                <div class="d-flex flex-column justify-content-start">
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Home
                  </a>
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>About Us
                  </a>
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Our
                    Services
                  </a>
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Meet The
                    Team
                  </a>
                  {/* <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Latest
                    Blog
                  </a> */}
                  <a class="text-body" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Contact
                    Us
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <h5 class="text-uppercase border-start border-5 border-primary ps-3 mb-4">
                  Popular Links
                </h5>
                <div class="d-flex flex-column justify-content-start">
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Home
                  </a>
                  <a class="text-body mb-2" href="#">
                    <i class="bi bi-arrow-right text-primary me-2"></i>About Us
                  </a>
                  <a class="text-body mb-2" href="/showcase">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Showcases
                  </a>
                  <a class="text-body mb-2" href="/themes">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Themes
                  </a>

                  <a class="text-body" href="/pricing">
                    <i class="bi bi-arrow-right text-primary me-2"></i>Pricing
                  </a>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <h5 class="text-uppercase border-start border-5 border-primary ps-3 mb-4">
                  Newsletter
                </h5>
                <form action="">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control p-3"
                      placeholder="Your Email"
                    />
                    <button class="btn btn-primary">Sign Up</button>
                  </div>
                </form>
                <Link
                  class=" mb-1 mx-1"
                  to="/VisitorsCount"
                  style={
                    {
                      // color: this.context.theme.mainNavBarTextColor,
                    }
                  }
                >
                  <i class="bi bi-arrow-right  me-2"></i> Visitors Count
                </Link>
                <h6 class="text-uppercase mt-4 mb-3">Follow Us</h6>

                <div class="d-flex">
                  <a
                    class="btn btn-outline-primary btn-square me-2"
                    href="https://twitter.com/ElasticCART"
                    target="_blank"
                  >
                    <i class="bi bi-twitter"></i>
                  </a>
                  <a
                    class="btn btn-outline-primary btn-square me-2"
                    href="https://www.facebook.com/ElasticCart1/"
                    target="_blank"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a
                    class="btn btn-outline-primary btn-square me-2"
                    href="https://www.youtube.com/@ElasticCart"
                    target="_blank"
                  >
                    <i class="bi bi-youtube"></i>
                  </a>
                  <a
                    class="btn btn-outline-primary btn-square me-2"
                    href="https://www.linkedin.com/in/ElasticCART-pos-and-ecommerce-aa697a272/"
                    target="_blank"
                  >
                    <i class="bi bi-linkedin"></i>
                  </a>
                  <a
                    class="btn btn-outline-primary btn-square"
                    href="https://www.instagram.com/ElasticCART/"
                    target="_blank"
                  >
                    <i class="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
              <div class="col-12 text-center text-body">
                <a
                  class="text-body"
                  href="https://ecdevstorage01.blob.core.windows.net/elasticcart/Terms%20of%20Service.pdf?sp=r&st=2023-04-12T13:15:42Z&se=2099-04-12T21:15:42Z&spr=https&sv=2021-12-02&sr=b&sig=lnEzBk7RD8w5%2FffI0qhx%2BtVbz9OpCITzqAg6Qov51Hg%3D"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
                <span class="mx-1">|</span>
                <a
                  class="text-body"
                  href="https://ecdevstorage01.blob.core.windows.net/elasticcart/Privacy%20Policy.pdf?sp=r&st=2023-04-12T13:14:28Z&se=2029-04-12T21:14:28Z&spr=https&sv=2021-12-02&sr=b&sig=LEs%2Fm4fqbVeB701X1DwTV50yvgY%2BvjEfr4My%2BVDk3BM%3D"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <span class="mx-1">|</span>
                <a class="text-body" href="">
                  Customer Support
                </a>
                <span class="mx-1">|</span>
                {/* <a class="text-body" href="">
                  Payments
                </a> */}
                <span class="mx-1">|</span>
                <a class="text-body" href="">
                  Help
                </a>
                <span class="mx-1">|</span>
                <a class="text-body" href="">
                  FAQs
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid bg-white text-white-50 py-4">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6 text-center text-md-start">
                <p class="mb-md-0">
                  &copy;{" "}
                  <a class="text-white" href="#">
                    Your Site Name
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
              <div class="col-md-6 text-center text-md-end">
                <p class="mb-0">
                  Designed by{" "}
                  <a class="text-white" href="https://htmlcodex.com">
                    HTML Codex
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="btn btn-primary py-3 fs-4 back-to-top">
          <i class="bi bi-arrow-up"></i>
        </a>
      </>
    );
  }
}

export class BenefitsElasticCart extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <>
        {/* Benefits */}
        <div class="container-fluid py-1">
          <div class="container">
            <div class="row gx-5 my-2">
              <div class="border-start border-5 border-primary ps-5 mb-5">
                <h6 class="text-primary text-uppercase">Benefits</h6>
                <h1 class="display-6 text-uppercase mb-0">
                  <b>Benefits of ElasticCART</b>
                </h1>
              </div>
              {window.innerWidth <= 1200 ? (
                <>
                  <div>
                    <img
                      src="/images/benefits.PNG"
                      alt="First slide"
                      style={{
                        height: "9cm",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <br />
                    <ul className="">
                      <li>
                        All-in-One Digital Solution for Retail, WholeSale,
                        Supply Chain, Manufacturing, and Enterprise Businesses
                      </li>
                      <li>It's a powerful Marketing and Selling Platform.</li>
                      <li>
                        User-friendly, responsive, and works on any device
                      </li>
                      <li>
                        Cloud and Elastic Hosting Model with an uptime of 99.5%
                      </li>
                      <li>Error-free software and free software upgrades.</li>
                      <li>Secured with SSL & OTP based Authentications</li>
                      <li>
                        Integrated with Email, SMS and WhatApp for notifications
                      </li>
                      <li>3rd party integration with SWIGGY, Zomato.</li>
                      <li>
                        Shiprocket(17+ delivery partners including DUNZO)
                        integration for delivery
                      </li>
                      <li>PREMIUM eCommerce themes and custom Themes</li>
                      <li>Power filters, AI based and Fuzzy Logic Searches</li>
                      <li>
                        Payment Gateway RazorPay / PhonePe integration. Any
                        custom Payment gateway integration on demand.
                      </li>
                      <li>Single and Multi varient products Support</li>
                      <li>Dashboards, Analytics and 360 view Reports</li>
                      <li>Excellent Customer support </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <Row className="">
                    <Col
                      xs={6}
                      className="text-center verticalaligncentertd"
                      style={{
                        // justifyContent: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <a href="/images/benefits.PNG">
                        <img
                          className="d-block  w-100 verticalaligncentertd align-middle"
                          src="/images/benefits.PNG"
                          alt="First slide"
                          style={{
                            height: "14cm",
                            width: "100%",
                            objectFit: "contain",
                            justifyContent: "center",
                            verticalAlign: "middle",
                          }}
                        />
                      </a>
                      <ul></ul>
                    </Col>
                    <Col
                      xs={6}
                      className=" verticalaligncentertd align-middle"
                      style={{
                        // justifyContent: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <ul
                        className="text-muted"
                        style={{ lineHeight: "2.0em", marginBottom: "15px" }}
                      >
                        <li>
                          All-in-One Digital Solution for Retail, WholeSale,
                          Supply Chain, Manufacturing, and Enterprise Businesses
                        </li>
                        <li>It's a powerful Marketing and Selling Platform.</li>
                        <li>
                          User-friendly, responsive, and works on any device
                        </li>
                        <li>
                          Cloud and Elastic Hosting Model with an uptime of
                          99.5%
                        </li>
                        <li>Error-free software and free software upgrades.</li>
                        <li>Secured with SSL & OTP based Authentications</li>
                        <li>
                          Integrated with Email, SMS and WhatApp for
                          notifications
                        </li>
                        <li>3rd party integration with SWIGGY, Zomato.</li>
                        <li>
                          Shiprocket(17+ delivery partners including DUNZO)
                          integration for delivery
                        </li>
                        <li>PREMIUM eCommerce themes and custom Themes</li>
                        <li>
                          Power filters, AI based and Fuzzy Logic Searches
                        </li>
                        <li>
                          Payment Gateway RazorPay / PhonePe integration. Any
                          custom Payment gateway integration on demand.
                        </li>
                        <li>Single and Multi varient products Support</li>
                        <li>Dashboards, Analytics and 360 view Reports</li>
                        <li>Excellent Customer support </li>
                      </ul>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class BusinessSpecificSolution extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <>
        {/* Busienss Specific Solution */}
        <div class="container-fluid py-1">
          <div class="container">
            <div class="row gx-5 my-2">
              <div class="border-start border-5 border-primary ps-5 mb-5">
                <h6 class="text-primary text-uppercase">Domain</h6>
                <h1 class="display-6 text-uppercase mb-0">
                  <b>Business Specific Solutions</b>
                </h1>
              </div>
              {/* <h2>Business Specific Solutions</h2> */}
              <Table className="border" style={{ verticalAlign: "middle" }}>
                <tbody>
                  <tr className="border">
                    {window.innerWidth > 1200 ? (
                      <>
                        <td width="10%">
                          <a href="/retail">
                            <img
                              className="d-block "
                              src="/img/retailandwholesale.png"
                              alt="First slide"
                              style={{ width: "2cm", height: "2cm" }}
                            />
                          </a>
                        </td>
                        <td width="90%">
                          <h4 className="">
                            Modern Solution for all Retail & Wholesale Biz
                          </h4>
                          <span>
                            <p style={{ textAlign: "justify" }}>
                              <li>
                                ElasticCART is next-generation Modern solution
                                for Retail & Wholesale businesses.
                              </li>
                              <li>
                                Product includes front-desk Billing
                                software(POS), eCommerce (B2C, B2B), KIOSK POS
                                for self-ordering, QRCode self-ordering POS,
                                purchase management, order management, 3rd party
                                delivery, return management, CRM and ERP
                                modules.
                              </li>
                              <div
                                className="text-primary text-end"
                                style={{ fontSize: "16px" }}
                              >
                                <a href="/Retail" className="text-dark">
                                  <Button>learn more...</Button>
                                </a>
                              </div>
                            </p>
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td colSpan={2} width="100%">
                          <h5 className="">
                            <img
                              className="text-center"
                              src="/img/retailandwholesale.png"
                              alt="First slide"
                              style={{ width: "2cm", height: "2cm" }}
                            />
                            Retail & Wholesale Solution
                          </h5>
                          <span>
                            <p style={{ textAlign: "justify" }}>
                              <a href="/Retail" className="text-dark">
                                <li>
                                  ElasticCART is next-generation Modern solution
                                  for Retail & Wholesale businesses.
                                </li>
                                <li>
                                  Product includes front-desk Billing
                                  software(POS), eCommerce (B2C, B2B), KIOSK POS
                                  for self-ordering, QRCode self-ordering POS,
                                  purchase management, order management, 3rd
                                  party delivery, return management, CRM and ERP
                                  modules.
                                </li>
                                <div
                                  className="text-primary text-end"
                                  style={{ fontSize: "16px" }}
                                >
                                  <Button>learn more...</Button>
                                </div>
                              </a>
                            </p>
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr className="border  w-100">
                    {window.innerWidth > 1200 ? (
                      <>
                        <td width="10%">
                          <img
                            className="d-block "
                            src="/img/restaurant.png"
                            alt="First slide"
                            style={{ width: "2cm", height: "2cm" }}
                          />
                        </td>
                        <td width="90%">
                          <h4 className="">Modern Restaurant Solution</h4>
                          <span>
                            <p style={{ textAlign: "justify" }}>
                              <li>
                                ElasticCART provides end-to-end solution for all
                                type of Retaurants (Online and Offline).
                              </li>
                              <p>
                                <li>
                                  Our Solution provides Point Of Sale (POS),
                                  Captain App, eCommerce, Table POS, QRCode POS,
                                  KIOSK Pos, Kitchen and Raw Materials
                                  Management, Orders and Delivery Management,
                                  CRM, Greet App, Receipe Management and
                                  Delivery App.{" "}
                                </li>
                                <p>
                                  <li>
                                    It integrated with Swiggy, Zomato, Payment
                                    Gateway and 3rd party delivery.{" "}
                                  </li>
                                </p>
                              </p>
                              <a href="/restaurant">
                                <div
                                  className="text-primary text-end"
                                  style={{ fontSize: "16px" }}
                                >
                                  <Button>learn more...</Button>
                                </div>
                              </a>
                            </p>
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td colSpan={2} width={"100%"} className="border-0 ">
                          <h5 className="">
                            <img
                              className="text-center"
                              src="/img/restaurant.png"
                              alt="First slide"
                              style={{ width: "2cm", height: "2cm" }}
                            />
                            Modern Restaurant Solution
                          </h5>
                          <span>
                            <p style={{ textAlign: "start" }}>
                              <li>
                                ElasticCART provides end-to-end solution for
                                managing restaurants(Online and physical) for
                                multi-cusine, small, medium, and chain-of-store
                                restaurants.{" "}
                              </li>
                              <p>
                                <li>
                                  Product comes with Point Of Sale (POS),
                                  Captain App, eCommerce, Table POS, QRCode POS,
                                  KIOSK Pos, Kitchen and Raw Materials
                                  Management, Orders and Delivery Management,
                                  CRM, Greet App, and Delivery App.{" "}
                                </li>
                                <p>
                                  <li>
                                    It integrated with Swiggy, Zomato, Payment
                                    Gateway and 3rd party delivery.{" "}
                                  </li>
                                </p>
                              </p>
                              <a href="/restaurant">
                                <div
                                  className="text-primary text-end"
                                  style={{ fontSize: "16px" }}
                                >
                                  <Button>learn more...</Button>
                                </div>
                              </a>
                            </p>
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr className="border">
                    {window.innerWidth > 1200 ? (
                      <>
                        <td width="10%">
                          <img
                            className="d-block "
                            src="/img/suplychain.png"
                            alt="First slide"
                            style={{ width: "2cm", height: "2cm" }}
                          />
                        </td>
                        <td width="90%">
                          <h4 className="">
                            Modern Supply Chain & ERP Solution
                          </h4>
                          <span>
                            <p style={{ textAlign: "justify" }}>
                              <li>
                                ElasticCART supports end-to-end supply-chain
                                management workflows such as Raw Material
                                Management, Procurement Management,
                                Manufacturing, Servicing, Shipping,
                                Storage/Warehouse, and Selling (B2B,B2C,D2C).
                              </li>
                              &nbsp;&nbsp;&nbsp;
                              <a href="/SupplyChain">
                                <div
                                  className="text-primary text-end"
                                  style={{ fontSize: "16px" }}
                                >
                                  <Button>learn more...</Button>
                                </div>
                              </a>
                            </p>
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td colSpan={2} width="100%">
                          <h5 className="">
                            <img
                              className="text-center"
                              src="/img/suplychain.png"
                              alt="First slide"
                              style={{ width: "2cm", height: "2cm" }}
                            />
                            Supply Chain / ERP Solution
                          </h5>
                          <span>
                            <p style={{ textAlign: "justify" }}>
                              <li>
                                ElasticCART supports end-to-end supply-chain
                                management workflows such as Raw Material
                                Management, Procurement Management,
                                Manufacturing, Servicing, Shipping,
                                Storage/Warehouse, and Selling (B2B,B2C,D2C).
                              </li>

                              <a href="/SupplyChain">
                                <div
                                  className="text-primary text-end"
                                  style={{ fontSize: "16px" }}
                                >
                                  <Button>learn more...</Button>
                                </div>
                              </a>
                            </p>
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export class DemoLinks extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      demoStores: [
        { businessType: "Grocery", storeName: "supermarket" },
        {
          businessType: "Vegetables & Fruits",
          storeName: "fruitsandvegetables",
        },
        { businessType: "Restaurant Multicuisine", storeName: "restaurant" },
        { businessType: "Veg Restaurants", storeName: "vegrestaurant" },
        { businessType: "Fashions", storeName: "fashion" },
        { businessType: "Sarees", storeName: "sarees" },
        { businessType: "Music Instruments", storeName: "Musicstore" },
        { businessType: "Audio Vinyl and CD Store", storeName: "AudioStore" },
        { businessType: "Aquairium & Fish", storeName: "Aquarium" },
        { businessType: "Machinery & Tools", storeName: "Machinery" },
        { businessType: "Mobile Store", storeName: "mobileshop" },
        { businessType: "Electronics", storeName: "electronics" },
        { businessType: "Bakery", storeName: "bakery" },
        { businessType: "Jewellery", storeName: "jewellery" },
        { businessType: "Furniture", storeName: "Furniture" },
        { businessType: "Floral", storeName: "Floral" },
        { businessType: "Books", storeName: "Books" },
        { businessType: "Pharmacy ", storeName: "Pharma" },
        { businessType: "Fish & Meat", storeName: "Meatshop" },
        { businessType: "Plants & Nursery", storeName: "Nursery" },
        { businessType: "Bikes & Cars Accessories", storeName: "BikeNCar" },
        { businessType: "Footwear /Shoe Store", storeName: "Footwear" },
        { businessType: "Sports & Gears", storeName: "Sports" },
      ],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="py-5">
          <div class="border-start border-5 border-primary ">
            <h6 class="text-primary text-uppercase">Demonistration</h6>
            <h5 class="display-6  mb-0">
              <b>Demo Links </b>
            </h5>
          </div>
          {window.innerWidth > 1200 ? (
            <>
              <Table striped>
                <thead>
                  <tr>
                    <th></th>
                    <th>Business Type</th>
                    <th>eCommerce</th>
                    <th>POS</th>
                    <th>Admin Portal</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.demoStores.map((m, i) => (
                    <>
                      <tr style={{ color: "black" }}>
                        <td>{i + 1}</td>
                        <td className="fw-bold">{m.businessType}</td>
                        <td>
                          <a
                            style={{ color: "blue" }}
                            href={"https://" + m.storeName + ".elasticcart.com"}
                            target="_blank"
                          >
                            {m.storeName + ".elasticcart.com"}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ color: "blue" }}
                            href={
                              "https://" + m.storeName + "-pos.elasticcart.com"
                            }
                            target="_blank"
                          >
                            {m.storeName + "-pos.elasticcart.com"}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ color: "blue" }}
                            href={
                              "https://" +
                              m.storeName +
                              "-admin.elasticcart.com"
                            }
                            target="_blank"
                          >
                            {m.storeName + "-admin.elasticcart.com"}
                          </a>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <Table stripped className="">
                <thead>
                  <tr>
                    <th></th>
                    {/* <th>Business Type</th> */}
                    <th>eCommerce, POS, Admin</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.demoStores.map((m, i) => (
                    <>
                      <tr style={{ color: "black" }}>
                        {/* <td className="fw-bold"></td> */}
                        <td>{i + 1}</td>
                        <td>
                          {m.businessType}
                          <br />
                          <a
                            style={{ color: "blue" }}
                            href={"https://" + m.storeName + ".elasticcart.com"}
                            target="_blank"
                          >
                            {m.storeName + ".elasticcart.com"}
                          </a>{" "}
                          <br />
                          <a
                            style={{ color: "blue" }}
                            href={
                              "https://" + m.storeName + "-pos.elasticcart.com"
                            }
                            target="_blank"
                          >
                            {m.storeName + "-pos.elasticcart.com"}
                          </a>
                          <br />
                          <a
                            style={{ color: "blue" }}
                            href={
                              "https://" +
                              m.storeName +
                              "-admin.elasticcart.com"
                            }
                            target="_blank"
                          >
                            {m.storeName + "-admin.elasticcart.com"}
                          </a>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </>
    );
  }
}
export class Testimonial extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-4 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
              <h1 class="mb-0">
                What Our Clients Say About Our Digital Services
              </h1>
            </div>
            <div class=" fadeInUp" data-wow-delay="0.6s">
              <div class="testimonial-item bg-light my-4">
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Mr.Rajan</h4>
                  <small class="text-uppercase">
                    Director of AllSaints Grocery & Bakery
                  </small>
                </div>

                <div class="pt-4 pb-5 px-5">
                  ElasticCART team helped my business sell online under my own
                  brand. My online store got up and running on the same day! I
                  appreciate all your great support. Looking forward to working
                  on other Projects.
                </div>
              </div>
              <div class="testimonial-item bg-light my-4">
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Mr.Ramachandran</h4>
                  <small class="text-uppercase">
                    Director of Pineappleexpress
                  </small>
                </div>

                <div class="pt-4 pb-5 px-5">
                  Now I'm selling products online (B2B and B2C) under my own
                  brand. This is a great feel. My online store looks stunning
                  and great. performance seeing with 5000+ products on my store.
                  Thanks to ElasticCART Team for great solution and support! I
                  am trying to replace my older POS system with ElasticCART's
                  POS now:). I'd recommend it to my friends.
                </div>
              </div>
              <div class="testimonial-item bg-light my-4">
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Mr.Manjunath</h4>
                  <small class="text-uppercase">Director of SpicyHut</small>
                </div>

                <div class="pt-4 pb-5 px-5">
                  We use the total solution of Restaurant Management Solution
                  from ElasticCART. It helps our business go to the next level
                  with powerful POS, eCommerce and KIOSK-based solutions. Our
                  Management productivity improved a lot and we see a lot of
                  savings.
                </div>
              </div>
              <div class="testimonial-item bg-light my-4">
                <div class="ps-4">
                  <h4 class="text-primary mb-1">Mr.Prasanna</h4>
                  <small class="text-uppercase">
                    Director of MGR (Meenakshi Gateway Restaurant)
                  </small>
                </div>

                <div class="pt-4 pb-5 px-5">
                  We are using POS and eCommerce products from ElasticCART. We
                  are happy with the product. Great Support and Kudos to the
                  ElasticCART Support Team for stunning Online Store.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class Testimonial1 extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      clientLogos: [],
      firstLoad: false,
    };
  }

  componentDidMount = () => {};
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 1500,
        cssEase: "linear",
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
          <div
            class="section-title text-center position-relative pb-3 mb-4 mx-auto"
            style={{ maxWidth: "600px;" }}
          >
            <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
            <h1 class="mb-0">
              What Our Clients Say About Our Digital Services
            </h1>
          </div>

          <Slider {...settings}>
            <div className="verticalaligncentertd bg-white">
              <Card
                style={{
                  width: "18rem",
                  height: "25rem",
                  backgroundColor: "lightgreen",
                }}
              >
                <Card.Body>
                  <Card.Title>Mr.Rajan</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Director of AllSaints Grocery & Bakery
                  </Card.Subtitle>
                  <Card.Text>
                    <span>
                      ElasticCART team helped my business sell online under my
                      own brand. My online store got up and running on the same
                      day! I appreciate all your great support. Looking forward
                      to working on other Projects.
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="verticalaligncentertd bg-white">
              <Card
                style={{
                  width: "18rem",
                  height: "25rem",
                  backgroundColor: "lightgreen",
                }}
              >
                <Card.Body>
                  <Card.Title>Mr.Ramachandran</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Director of Pineappleexpress Grocery
                  </Card.Subtitle>
                  <Card.Text>
                    Now I'm selling products online (B2B and B2C) under my own
                    brand. This is a great feel. My online store looks stunning
                    and great. performance seeing with 5000+ products on my
                    store. Thanks to ElasticCART Team for great solution and
                    support! I am trying to replace my older POS system with
                    ElasticCART's POS now:). I'd recommend it to my friends.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="verticalaligncentertd bg-white">
              <Card
                style={{
                  width: "18rem",
                  height: "25rem",
                  backgroundColor: "lightgreen",
                }}
              >
                <Card.Body>
                  <Card.Title>Mr.Manjunath</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Director of SpicyHut
                  </Card.Subtitle>
                  <Card.Text>
                    We use the total solution of Restaurant Management Solution
                    from ElasticCART. It helps our business go to the next level
                    with powerful POS, eCommerce and KIOSK-based solutions. Our
                    Management productivity improved a lot and we see a lot of
                    savings.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="verticalaligncentertd bg-white">
              <Card
                style={{
                  width: "18rem",
                  height: "25rem",
                  backgroundColor: "lightgreen",
                }}
              >
                <Card.Body>
                  <Card.Title>Mr.Prasanna</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Director of MGR (Meenakshi Gateway Restaurant)
                  </Card.Subtitle>
                  <Card.Text>
                    We are using POS and eCommerce products from ElasticCART. We
                    are happy with the product. Great Support and Kudos to the
                    ElasticCART Support Team for stunning Online Store.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export class ClientsSlider extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      clientLogos: [],
      firstLoad: false,
    };
  }

  componentDidMount = () => {};
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        // nextArrow: <Sample
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <div className="text-center px-5 py-1 my-1 border-0">
        <div className="px-2 my-5 m-2 title-dsn text-center " style={{}}>
          <h1 class="bold_font">OUR CLIENTS</h1>
        </div>

        <Slider {...settings}>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/allsaints.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/godwill.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/mediuna.png"}
              className=""
              alt="..."
              // width="40px"
              // height="120px"
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/mgr.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/cura.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/green.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/lifestyleaqua.jpg"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/pets4you.PNG"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/rainbow.PNG"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/pineappleexpress.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/colourconversation.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/genius.png"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
          <div className="verticalaligncentertd bg-white">
            <img
              src={"/images/MaruthamStoreLogo.PNG"}
              className=""
              alt="..."
              style={{
                objectFit: "contain",
                width: "4cm",
                height: "3cm",
              }}
            ></img>{" "}
          </div>
        </Slider>
      </div>
    );
  }
}
